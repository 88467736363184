// Angular
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// local
import { AuthGuardService } from './services/auth-guard.service';
import { LeaveGuardService } from './services/leave-guard.service';
import { TranslationsComponent } from './pages/misc/translations/translations.component';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { TermsOfUseComponent } from './pages/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';

const routes: Routes = [
    {
        path: ':language_code/login',
        loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule)
    },
    {
        path: ':language_code/firstlogin',
        loadChildren: () => import('./modules/first-login/first-login.module').then((m) => m.FirstLoginModule)
    },
    {
        path: ':language_code/reset-password',
        loadChildren: () => import('./modules/reset-password/reset-password.module').then((m) => m.ResetPasswordModule)
    },
    // MISC
    {
        path: 'translations',
        component: TranslationsComponent,
        canDeactivate: [LeaveGuardService],
        canActivate: [AuthGuardService],
        data: { title: 'async', hideNavigation: true, hideFooter: true, type: 'translations' }
    },
    {
        path: ':language_code/cookie-policy',
        component: CookiePolicyComponent,
        data: { title: 'cookie-policy', hideNavigation: true, public: true }
    },
    {
        path: ':language_code/privacy-policy',
        component: PrivacyPolicyComponent,
        data: { title: 'privacy-policy', hideNavigation: true, public: true }
    },
    {
        path: ':language_code/terms-of-use',
        component: TermsOfUseComponent,
        data: { title: 'terms-of-use', hideNavigation: true, public: true }
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    anchorScrolling: 'enabled'
})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
