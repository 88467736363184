<app-static-page-wrapper
    [currentLanguage]="languageCode"
    (selectLanguage)="onSelectLanguageCode($event)"
>
    <ng-container *ngIf="languageCode === 'nl'">
        <h2>COOKIE POLICY</h2>
        <h3>ALGEMEEN</h3>
        <p>Deze Cookieverklaring regelt het gebruik van cookies en soortgelijke technologieën (zoals Google analytics-advertentiefuncties, enz.) als een onderdeel van uw gebruik van onze Website (de “Website”) en/of alle erop aangeboden diensten of diensten die daarmee verband houden (gezamenlijk aangeduid als de “Diensten”). In deze Cookieverklaring wordt, onder andere, aangegeven welke cookies op de website door MilkBE gebruikt worden en waarom.</p>
        <p>Cookies zijn kleine tekstbestanden die op de harde schijf van een toestel worden opgeslagen en bepaalde informatie bevatten, waaronder soms ook persoonsgegevens. Wanneer u onze Website en/of Diensten gebruikt, verzamelen en verwerken wij ook verschillende soorten persoonlijke informatie, ook andere dan via cookies en soortgelijke technologieën. Als u hierover meer wilt weten, lees dan onze Privacyverklaring.</p>
        <p>De verwerking van gegevens verzameld door gebruik van deze technologieën wordt uitgevoerd door MilkBE vzw als verantwoordelijke voor de verwerking, met zetel te 3012 Leuven aan de Kolonel Begaultlaan 1A bus 11 en met ondernemingsnummer 0725.808.537, in overeenstemming met de toepasselijke wetgeving inzake de bescherming van persoonsgegevens, dit behoudens expliciet anders aangegeven, in welk geval niet MilkBE, maar een expliciet aangeduide derde de verantwoordelijke voor de verwerking is.</p>
        <p><b>Door gebruik te (blijven) maken van deze Website en/of de Diensten, bevestigt u integraal kennis te hebben genomen van deze Cookieverklaring en hiermee integraal akkoord te gaan.</b></p>
        <p>Indien u hiermee niet integraal akkoord gaat, verlaat u nu op eigen initiatief de website en maakt u op geen enkele wijze gebruik van deze Website en/of de Diensten, hetzij wijzigt u nu op eigen initiatief uw cookievoorkeuren zoals uiteengezet in deze Cookieverklaring.</p>
        <p>MilkBE behoudt zich het recht voor om de Cookieverklaring (regelmatig) te wijzigen volgens eigen inzicht. Dergelijke wijzigingen, toevoegingen of vervangingen zullen onder uw aandacht worden gebracht via onze Website en/of desgevallend via de Diensten. Als u het niet eens bent met deze wijzigingen, toevoegingen of vervangingen, vragen wij u een e-mail te sturen naar office&#64;milkbe.org. Indien wij hiervan geen mededeling hebben ontvangen binnen vijf (5) werkdagen nadat de wijzigingen, aanvullingen of vervangingen via onze Website of Diensten aan u zijn meegedeeld, wordt u geacht alle wijzigingen, aanvullingen of vervangingen te hebben aanvaard. Hou er rekening mee dat de intrekking van uw akkoord met de voorwaarden kan impliceren dat u niet langer in staat bent om de Website en/of Diensten geheel of gedeeltelijk te gebruiken.</p>
        <p>U stemt er mee in dat uw voortgezet gebruik van de Website en/of de Diensten wordt begrepen als een voortgezette toestemming. U kan uw toestemming op elk ogenblik intrekken door een verzoek te richten via e-mail naar office&#64;milkbe.org.</p>
        <br>
        <p><b>Deze cookiesverklaring werd laatst bijgewerkt op 3/03/2021.</b></p>
        <br>
        <h3>WELKE COOKIES EN SOORTGELIJKE TECHNOLOGIEËN EN WAAROM WE DEZE GEBRUIKEN</h3>
        <p>Wanneer u de diensten van MilkBE gebruikt, kan MilkBE of een service provider, cookies (gegevensbestandjes op de schijf van uw toestel of mobiele apparaat), webbeacons (elektronische beelden die in de code van een webpagina worden geplaatst) of gelijkaardige technologieën gebruiken.</p>
        <p>MilkBE kan deze cookies en gelijkaardige technologieën gebruiken om gegevens te verzamelen, om u te identificeren als gebruiker, om u een betere gebruikerservaring op onze website te bieden, om de promotionele effectiviteit te meten, om services op maat en gerichte advertenties aan te bieden en om vertrouwen en veiligheid te bevorderen en te verzekeren op onze website.</p>
        <h3>DE MILKBE-WEBSITE KAN GEBRUIK MAKEN VAN VERSCHILLENDE SOORTEN COOKIES.</h3>
        <p>Wanneer u de Website en/of Diensten bezoekt en/of gebruikt, plaatsen wij cookies of gebruiken we soortgelijke technologieën waarmee we persoonsgegevens over u verzamelen en verwerken. De website kan volgende cookies en soortgelijke technologieën omvatten (de lijst is alomvattend en deze website gebruikt niet noodzakelijk alle hieronder vermelde cookies):</p>
        <img src="/assets/img/misc/cookie-policy.png" alt="cookies" width="750" height="233">
        <p>De website heeft de Google Analytics-advertentiefuncties ingeschakeld voor het verkrijgen van demografische- en interesserapporten. Deze gegevens kunnen eventueel gebruikt worden voor direct marketing doeleinden. De aldus verkregen informatie wordt, met inbegrip van het adres van uw computer (IP-adres), overgebracht naar en door Google opgeslagen op servers in de Verenigde Staten van America.</p>
        <p>Lees het privacybeleid van Google voor meer informatie, alsook het specifieke privacybeleid van Google Analytics. U kunt zich afmelden van de Google Analytics Advertentiefuncties via de Ads Preferences Manager door te kiezen voor ‘afmelden’. Aanvullend kunt u zichzelf uitsluiten van opname in onze webstatistieken door gebruik te maken van deze Browser Add-on.</p>
        <p>Google gebruikt deze informatie om bij te houden hoe onze website gebruikt wordt, om rapporten over de website aan ons te kunnen verstrekken en om haar adverteerders informatie over de effectiviteit van hun campagnes te kunnen bieden. Google kan deze informatie aan derden verschaffen indien Google hiertoe wettelijk wordt verplicht, of voor zover deze derden de informatie namens Google verwerken.</p>
        <p>MilkBE heeft hier geen invloed op, de verantwoordelijke voor de verwerking is Google LLC, met zetel te 1600 Amphitheatre Parway, Mountain View, CA 94043, VS. MilkBE heeft Google alleen toegestaan de verkregen analytics informatie te gebruiken voor diensten van Google zelf.</p>
        <h3>ZELF UW COOKIEVOORKEUREN INSTELLEN</h3>
        <p>Via uw browserinstellingen kunt u de installatie van cookies weigeren. U kunt ook op elk moment de reeds geïnstalleerde cookies verwijderen van uw computer of mobiel apparaat. Hulp bij het aanpassen van deze instellingen kunt u vinden op de website van de browser die u gebruikt. Houd er rekening mee dat het niet toestaan van cookies bij het bezoeken van de Website ertoe kan leiden dat sommige of alle functies van de Website niet naar behoren werken.</p>
        <ul>
            <li>Internet Explorer: https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies;</li>
            <li>Microsoft Edge: http://windows.microsoft.com/en-gb/windows-10/edge-privacy-faq;</li>
            <li>Chrome: https://support.google.com/chrome/answer/95647?hl=en;</li>
            <li>Firefox: https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences;</li>
            <li>Safari: https://support.apple.com/en-in/guide/safari/sfri11471/mac;</li>
        </ul>
        <p>Via ‘Your online choice’ kunt u zelf bepalen of u wel of geen gebruik wilt maken van interest based advertising.</p>
        <p>Voor verdere informatie over het verwijderen of blokkeren van cookies kan je volgende website bezoeken: http://www.aboutcookies.org.</p>
    </ng-container>
    <ng-container *ngIf="languageCode === 'fr'">
        <h2>DÉCLARATION RELATIVE AUX COOKIES</h2>
        <h3>GÉNÉRALITÉS</h3>
        <p>La présente déclaration relative aux cookies régit l’utilisation des cookies et des technologies similaires (fonctions publicitaires de Google analytics, etc.) dans le cadre de votre utilisation de notre Site internet (le “Site”) et/ou tous les services proposés par notre Site ou en relation avec notre Site (dénommés collectivement les “Services”). La présente déclaration spécifie notamment quels cookies sont utilisés sur le Site par MilkBE et pourquoi.</p>
        <p>Les cookies sont de petits fichiers texte enregistrés sur le disque dur d’un appareil et qui contiennent certaines informations, qui peuvent être des données personnelles. Lorsque vous utilisez notre Site et/ou nos Services, nous récoltons et traitons aussi d’autres informations personnelles que celles passant par les cookies et les technologies similaires. Pour en savoir plus à ce sujet, consultez notre Déclaration de confidentialité.</p>
        <p>Le traitement des données récoltées par l’utilisation de ces technologies est assuré par MilkBE asbl comme responsable du traitement, dont le siège est situé à 3012 Leuven, Kolonel Begaultlaan 1A boîte 11, dont le numéro d’entreprise est le 0725.808.537, conformément à la législation applicable dans le cadre de la protection des données personnelles, sauf mention contraire explicite, auquel cas ce n’est pas MilkBE, mais un tiers explicitement nommé, qui est responsable du traitement.</p>
        <p><b>En utilisant (continuant à utliser) le présent Site et/ou les Services, vous confirmez avoir intégralement pris connaissance de la présente déclaration relative aux cookies, et vous déclarez l’accepter intégralement.</b></p>
        <p>Si ce n’est pas le cas, quittez ce site de votre initiative et n’utilisez d’aucune façon ce Site et/ou les Services, ou modifiez dès maintenant vos préférences en matière de cookies, comme cela est prévu dans la présente déclaration.</p>
        <p>MilkBE se réserve le droit de modifier (régulièrement) la présente déclaration relative aux cookies de sa propre initiative. Ces modifications, ajouts ou substitutions seront portés à votre connaissance via notre Site et/ou les Services, le cas échéant. Si vous n’acceptez pas ces modifications, ajouts ou substitutions, nous vous demandons d’envoyer un courriel à office&#64;milkbe.org. Si nous ne recevons aucune communication de votre part dans les cinq (5) jours ouvrables qui suivent la communication des modifications, ajouts ou substitutions via notre Site ou nos Services, vous êtes considéré comme ayant accepté toutes les modifications, tous les ajouts ou toutes les substitutions. Tenez compte du fait que le retrait de votre accord des conditions peut impliquer que vous ne soyez plus en mesure d’utiliser en tout ou en partie le Site et/ou les Services.</p>
        <p>Vous acceptez le fait que votre poursuite de l’utilisation du Site et/ou des Services est considérée comme la poursuite de votre accord. Vous pouvez retirer votre accord à tout moment en envoyant une requête par courriel à office&#64;milkbe.org.</p>
        <br>
        <p><b>La présente déclaration relative aux cookies a été actualisée pour la dernière fois le 3/03/2021.</b></p>
        <br>
        <h3>QUELS COOKIES ET QUELLES TECHNOLOGIES SIMILAIRES UTILISONS-NOUS ET POURQUOI</h3>
        <p>Lorsque vous utilisez les services de MilkBE, MilkBE ou un fournisseur de service peut utiliser des cookies (petits fichiers de données enregistrés sur le disque dur de votre appareil ou de votre appareil mobile), des balises internet (images électroniques insérées dans le code d’une page internet) ou des technologies similaires.</p>
        <p>MilkBE peut utiliser ces cookies et technologies similaires pour récolter des données, pour vous identifier en tant qu’utilisateur, vous permettre une meilleure expérience de notre site, mesurer l’efficacité promotionnelle, fournir des services sur mesure et des publicités ciblées, et favoriser et garantir la confiance et la sécurité sur notre site.</p>
        <h3>LE SITE MILKBE PEUT UTILISER DIFFÉRENTS TYPES DE COOKIES</h3>
        <p>Lorsque vous visitez et/ou utilisez le Site et/ou les Services, nous insérons des cookies ou utilisons des technologies similaires nous permettant de récolter et de traiter des informations personnelles à votre sujet. Le Site peut comporter les cookies et les technologies similaires suivantes (la liste est globale et ce Site n’utilise pas nécessairement tous les cookies repris ci-dessous):</p>
        <img src="/assets/img/misc/cookie-policy.png" alt="cookies" width="750" height="233">
        <p>Le site a activé les fonctions publicitaires de Google Analytics pour l’obtention de rapports démographiques et d’intérêt. Ces données peuvent être utilisées à des fins de marketing direct. Les données ainsi obtenues sont transmises, y compris l’adresse IP de votre ordinateur (adresse IP), à des serveurs de Google aux Etats-Unis.</p>
        <p>Consultez la politique de confidentialité de Google pour en savoir plus, ainsi que la politique de confidentialité spécifique de Google Analytics. Vous pouvez vous désabonner des fonctions publicitaires de Google Analytics via l’Ads Preferences Manager, en cliquant sur ‘désabonnement’. Vous pouvez également vous exclure de tout enregistrement dans nos statistiques internet en utilisant ce Browser Add-on.</p>
        <p>Google utilise ces informations pour en apprendre davantage sur la façon dont notre site est utilisé, pour nous fournir des rapports sur notre site et pour fournir des informations sur l’efficacité des campagnes publicitaires à des annonceurs. Google peut fournir ces informations à des tiers si Google y est légalement tenu, ou pour autant que ces tiers traitent les données au nom de Google.</p>
        <p>MilkBE n’a aucune influence à ce niveau, le responsable du traitement est Google LLC, dont le siège se trouve au 1600 Amphitheatre Parway, Mountain View, CA 94043, Etats-Unis. MilkBE a juste autorisé Google à utiliser les informations analytiques obtenues pour les services de Google.</p>
        <h3>PARAMÉTRAGE DE VOS PRÉFÉRENCES EN MATIÈRE DE COOKIES</h3>
        <p>Les paramètres de votre navigateur vous permettent de refuser l’installation de cookies. Vous pouvez à tout moment supprimer les cookies déjà installés sur votre ordinateur ou appareil mobile. Vous trouverez de l’aide pour modifier ces paramètres sur le site de votre navigateur. Tenez compte du fait que ne pas autoriser les cookies lors de la consultation du Site peut empêcher le bon fonctionnement de certaines fonctions du Site, voire de toutes les fonctions du Site.</p>
        <ul>
            <li>Internet Explorer: https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies;</li>
            <li>Microsoft Edge: http://windows.microsoft.com/en-gb/windows-10/edge-privacy-faq;</li>
            <li>Chrome: https://support.google.com/chrome/answer/95647?hl=en;</li>
            <li>Firefox: https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences;</li>
            <li>Safari: https://support.apple.com/en-in/guide/safari/sfri11471/mac;</li>
        </ul>
        <p>Via ‘Your online choice’, vous pouvez déterminer si vous souhaitez ou non utiliser l’interested based advertising.</p>
        <p>Pour de plus amples informations sur la suppression ou le blocage des cookies, consultez le site: http://www.aboutcookies.org.</p>
    </ng-container>
</app-static-page-wrapper>
