<app-static-page-wrapper
    [currentLanguage]="languageCode"
    (selectLanguage)="onSelectLanguageCode($event)"
>
    <ng-container *ngIf="languageCode === 'nl'">
        <h2>PRIVACYVERKLARING</h2>
        <h3>ALGEMEEN</h3>
        <p>Deze Privacyverklaring regelt de verwerking van uw persoonsgegevens als een onderdeel van uw gebruik van onze Website (de “Website”) en/of alle erop aangeboden diensten of diensten die daarmee verband houden (gezamenlijk aangeduid als de “Diensten”).</p>
        <p>Deze verwerking wordt uitgevoerd door MilkBE vzw als verantwoordelijke voor de verwerking, met zetel te 3012 Leuven aan de Kolonel Begaultlaan 1A bus 11 en met ondernemingsnummer 0725.808.537, in overeenstemming met de toepasselijke wetgeving inzake de bescherming van persoonsgegevens, dit behoudens expliciet anders aangegeven, in welk geval niet MilkBE, maar een expliciet aangeduide derde de verantwoordelijke voor de verwerking is.</p>
        <p>In deze Privacyverklaring wordt, onder andere, aangegeven welke gegevens op de website door MilkBE worden verzameld, hoe MilkBE deze gegevens gebruikt, hoe de gegevens worden beveiligd en welke uw persoonlijke rechten aangaande zijn.</p>
        <p><b>Door gebruik te (blijven) maken van deze Website en/of de Diensten, bevestigt u integraal kennis te hebben genomen van deze Privacyverklaring en hiermee integraal akkoord te gaan.</b></p>
        <p>Indien u hiermee niet integraal akkoord gaat, verlaat u nu op eigen initiatief de website en maakt u op geen enkele wijze gebruik van deze Website en/of de Diensten.</p>
        <p>MilkBE behoudt zich het recht voor om de Privacyverklaring (regelmatig) te wijzigen volgens eigen inzicht. Dergelijke wijzigingen, toevoegingen of vervangingen zullen onder uw aandacht worden gebracht via onze Website en/of desgevallend via de Diensten. Als u het niet eens bent met deze wijzigingen, toevoegingen of vervangingen, vragen wij u een e-mail te sturen naar office&#64;milkbe.org.</p>
        <p>Indien wij hiervan geen mededeling hebben ontvangen binnen vijf (5) werkdagen nadat de wijzigingen, aanvullingen of vervangingen via onze Website of Diensten aan u zijn meegedeeld, wordt u geacht alle wijzigingen, aanvullingen of vervangingen te hebben aanvaard. Hou er rekening mee dat de intrekking van uw akkoord met de voorwaarden kan impliceren dat u niet langer in staat bent om de Website en/of Diensten geheel of gedeeltelijk te gebruiken.</p>
        <p>Door de privacy opt-in checkbox aan te klikken op formulieren op de Website, verklaart u dat u inderdaad deze Privacyverklaring gelezen heeft en hiermee instemt. U verklaart dat u begrijpt met welke doeleinden uw persoonsgegevens worden verwerkt. U stemt er ook mee in dat uw voortgezet gebruik van de Website en/of de Diensten wordt begrepen als een voortgezette toestemming. U kan uw toestemming op elk ogenblik intrekken door een verzoek te richten via e-mail naar office&#64;milkbe.org.</p>
        <p>U garandeert dat de gegevens die u aan MilkBE meedeelt u toebehoren, of althans dat u toestemming heeft om ze te gebruiken en aan MilkBE mee te delen. MilkBE kan u de toegang tot haar Website(s) en/of Diensten of delen daarvan ontzeggen, tijdelijk of definitief, wanneer u gebruik maakt van valse gegevens of van gegevens van derden zonder hun toestemming.</p>
        <p>Indien er op onze Website of in onze Diensten iets is wat niet werkt zoals u het verwacht als het gaat om uw privacy, kunt u contact met ons opnemen met het secretairaat: MilkBE vzw, Kolonel Begaultlaan 1A bus 11, 3012 Leuven of office&#64;milkbe.org of 016 30 07 70.</p>
        <br>
        <p><b>Deze privacyverklaring werd laatst bijgewerkt op 03/03/2021.</b></p>
        <br>
        <h3>WELKE GEGEVENS EN WAAROM WE DEZE GEGEVENS VERWERKEN</h3>
        <p>MilkBE verzamelt gegevens om effectief te kunnen werken, om u de beste ervaringen met onze Website en Diensten te kunnen bieden, om met u in contact te treden, om u de producten of diensten te kunnen leveren die u hebt gevraagd of waarvoor u zich heeft geregistreerd, om u te informeren over het eigen aanbod aan producten en diensten (zoals o.m. data waarop MilkBE evenementen plaatsvinden, etc.), voor het personaliseren van het eigen aanbod aan producten en diensten en eventueel voor direct marketing doeleinden.</p>
        <p>U geeft een aantal van deze gegevens rechtstreeks aan ons door, bijvoorbeeld wanneer u zich inschrijft voor een Dienst, wanneer u contact met ons opneemt met een vraag, enz. Een deel van de gegevens verkrijgen we door vast te leggen hoe u met onze Website en Diensten omgaat, bijvoorbeeld door het gebruik van technologieën zoals cookies. Hou er aldus rekening mee dat MilkBE zogenaamde ‘cookies’ of gelijkaardige technologieën kan gebruiken als deel van de Website en/of de Diensten. Cookies zijn kleine tekstbestanden die geplaatst worden op de harde schijf van een toestel, en die bepaalde informatie bevatten, waaronder soms persoonsgegevens. Voor meer informatie betreffende ons gebruik van cookies, gelieve onze Cookieverklaring te lezen.</p>
        <p>Wanneer u de Website en/of Diensten gebruikt, verwerken we persoonsgegevens die op u betrekking hebben (zoals bijvoorbeeld, maar niet exhaustief en niet altijd, uw voornaam, familienaam, functie, e-mailadres, telefoonnummer en foto) en dit telkens voor de nuttige bewaringstermijn. Zulke persoonsgegevens kunnen omvatten:</p>
        <img src="/assets/img/misc/cookie-policy.png" alt="cookies" width="750" height="233">
        <p>In principe verkrijgen wij de hierboven genoemde persoonsgegevens rechtstreeks van u. Wij sturen geen van de persoonlijke gegevens die u via de Website verstrekt door aan providers van social media, tenzij u hiermee instemt. Naast de bovengenoemde doeleinden kunnen wij uw persoonlijke gegevens ook verwerken:</p>
        <ul>
            <li>Om u op een gepersonaliseerde en efficiënte manier te voorzien van de informatie over producten en diensten die u aanvraagt, hetzij via de Website, e-mail, telefoon of social media kanalen.</li>
            <li>Voor direct marketing-doeleinden, d.w.z. om u te kunnen voorzien van gerichte communicatie, promoties, aanbiedingen en andere advertenties van ons of onze geselecteerde partners. Hiervoor zullen wij uw voorafgaande toestemming vragen.</li>
            <li>Voor het uitvoeren van statistische analyses om onze Website en/of Diensten te verbeteren, of het ontwikkelen van nieuwe producten of diensten.</li>
            <li>Om te verstrekken aan een financiële instelling of betalingsdienstaanbieder, om uw financiële instelling en de betalingsdienstaanbieder in staat te stellen aan hun (wettelijke) verplichtingen te voldoen.</li>
            <li>Om over te dragen aan de politie of de gerechtelijke autoriteiten als bewijs van mogelijke misdrijven of indien er gegronde verdenkingen bestaan van een onrechtmatige daad of misdrijf door u gepleegd door middel van uw registratie bij of gebruik van de Website of de Diensten.</li>
        </ul>
        <p>Als en wanneer uw registratie op de Website of gebruik van de Website of Diensten kan worden beschouwd als (a) een schending van de voorwaarden of de intellectuele eigendomsrechten of enig ander recht van een derde partij, (b) een bedreiging voor de veiligheid of integriteit van de Diensten, (c) een gevaar voor de Website of Diensten of systemen van ons of van onze onderaannemers als gevolg van virussen, Trojaanse paarden, spyware, malware, of enige andere vorm van kwaadaardige code, of (d) op welke manier dan ook illegaal of onrechtmatig, discriminerend of beledigend is, mogen wij uw gegevens verwerken in het belang van onszelf, onze partners of derden.</p>
        <h3>MET WIE EN WAAROM WIJ GEGEVENS DELEN</h3>
        <p>Wij verzenden uw persoonsgegevens niet op een identificeerbare manier naar derden als dit niet vereist is om de Diensten te verlenen, zonder uw uitdrukkelijke toestemming daartoe. Wij kunnen een beroep doen op externe verwerkers om u de Website en/of Diensten aan te bieden. Wij zorgen ervoor dat derde verwerkers uw persoonsgegevens alleen namens ons, en op schriftelijke instructie van ons, mogen verwerken. Wij garanderen dat alle externe verwerkers met de nodige zorgvuldigheid worden geselecteerd zodat wij kunnen rekenen op de veiligheid en integriteit van uw persoonsgegevens. Wij kunnen geanonimiseerde en/of geaggregeerde gegevens overmaken aan andere organisaties die deze gegevens kunnen gebruiken om producten en diensten te verbeteren en de marketing, presentatie en verkoop van producten en diensten op maat te organiseren.</p>
        <h3>WAAR WIJ GEGEVENS VERWERKEN</h3>
        <p>Wijzelf en onze externe verwerkers zullen uw identificeerbare persoonsgegevens alleen in de EER verwerken. Wij kunnen uw geanonimiseerde en/of geaggregeerde gegevens overdragen aan organisaties buiten de EER. Indien een dergelijke overdracht plaatsvindt, zullen wij ervoor zorgen dat er passende waarborgen zijn om de veiligheid en integriteit van uw persoonlijke gegevens te garanderen, en dat alle rechten met betrekking tot persoonsgegevens gegarandeerd worden die u zou kunnen genieten onder het toepasselijk dwingend recht.</p>
        <p>Als er een overdracht van uw persoonsgegevens en/of geanonimiseerde en/of geaggregeerde gegevens plaatsvindt naar de VSA (Google), wordt het volgende wettelijke beschermingsmechanisme ingevoerd: Bindende bedrijfsvoorschriften.</p>
        <h3>HOE WIJ GEGEVENS VERWERKEN</h3>
        <p>Wij doen ons uiterste best om alleen de persoonsgegevens te verwerken die nodig zijn om de in deze Cookievoorwaarden vermelde doeleinden te bereiken. Wij zullen uw persoonsgegevens op een wettelijke, eerlijke en transparante manier verwerken. Wij doen ons uiterste best om de persoonsgegevens accuraat en up-to-date te houden.</p>
        <p>Uw persoonsgegevens worden alleen verwerkt zolang als nodig is om de in onze Privacy- en Cookievoorwaarden vermelde doeleinden te bereiken of tot het moment waarop u uw toestemming voor de verwerking ervan intrekt. Hou er rekening mee dat de intrekking van de toestemming kan impliceren dat u niet langer in staat bent om de Website en/of Diensten geheel of gedeeltelijk te gebruiken. Als u zich bij onze Website heeft geregistreerd (indien van toepassing), zullen wij uw persoonsgegevens verwijderen als u uw profiel verwijdert, tenzij een wettelijke of reglementaire verplichting of een gerechtelijk of administratief bevel ons dat zou verhinderen.</p>
        <p>Wij zullen de gepaste technische en organisatorische maatregelen nemen om uw persoonsgegevens veilig te houden tegen ongeoorloofde toegang of diefstal en tegen onopzettelijk verlies, manipulatie of vernietiging. Toegang door ons personeel of personeel van onze externe verwerkers is strikt gelimiteerd en alleen mogelijk op een need-to-know basis en is onderworpen aan strikte geheimhoudingsverplichtingen. U begrijpt echter dat de zorg voor veiligheid en beveiliging slechts inspanningsverplichtingen naar beste vermogen zijn, die nooit kunnen worden gegarandeerd.</p>
        <h3>UW RECHTEN</h3>
        <p>U heeft het recht om inzage te vragen tot alle persoonsgegevens die wij over u verwerken. Verzoeken om inzage die klaarblijkelijk worden ingediend met oog op het veroorzaken van overlast of schade voor ons, worden echter niet behandeld.</p>
        <p>U heeft het recht om te vragen dat alle persoonsgegevens over u die onjuist of onnauwkeurig zijn, kosteloos worden gecorrigeerd. Indien een dergelijk verzoek wordt ingediend, dient u bij dit verzoek ook het bewijs te voegen dat de persoonsgegevens waarvoor correctie wordt gevraagd, onjuist zijn.</p>
        <p>U heeft het recht om uw eerder gegeven toestemming voor de verwerking van uw persoonsgegevens in te trekken. U kunt uw toestemming te allen tijde intrekken door een e-mail met dit verzoek te sturen naar office&#64;milkbe.org of door uw profiel te verwijderen (indien van toepassing), of indien u uw toestemming voor de verwerking van uw persoonsgegevens voor een bepaalde Dienst wenst in te trekken kan u de daartoe desgevallend voorziene uitschrijfmogelijkheden benutten.</p>
        <p>U heeft het recht om te vragen dat persoonsgegevens met betrekking tot u worden verwijderd als deze niet langer nodig zijn in het licht van de doeleinden die in deze Privacyvoorwaarden worden uiteengezet of als u uw toestemming voor de verwerking ervan intrekt. U dient er echter rekening mee te houden dat een verzoek tot verwijdering door ons zal worden beoordeeld in het licht van wettelijke of reglementaire verplichtingen of administratieve of gerechtelijke bevelen die ons kunnen verhinderen de betreffende persoonsgegevens te verwijderen. In de plaats van verwijdering kunt u ook vragen dat wij de verwerking van uw persoonsgegevens beperken indien (a) u de juistheid van die gegevens betwist, (b) de verwerking onwettig is of (c) de gegevens niet langer nodig zijn voor de genoemde doeleinden, maar u ze nodig heeft om uzelf te verdedigen in gerechtelijke procedures.</p>
        <p>U heeft het recht om zich te verzetten tegen de verwerking van persoonsgegevens indien u kunt aantonen dat er ernstige en gerechtvaardigde redenen zijn die verband houden met bijzondere omstandigheden en die een dergelijk verzet rechtvaardigen. Als de beoogde verwerking echter als direct marketing wordt aangemerkt, heeft u het recht zich kosteloos en zonder rechtvaardiging te verzetten tegen een dergelijke verwerking.</p>
        <p>Wanneer uw persoonsgegevens worden verwerkt op basis van toestemming of op basis van een contract en de verwerking met geautomatiseerde middelen gebeurt, heeft u het recht om de door u aan MilkBE verstrekte persoonsgegevens in een gestructureerd, algemeen gebruikt en machinaal leesbaar formaat te ontvangen en, indien technisch mogelijk, heeft u het recht deze gegevens rechtstreeks door te geven aan een andere dienstverlener. De technische haalbaarheid hiervan wordt uitsluitend beoordeeld door MilkBE.</p>
        <p>Als u een verzoek wilt indienen om één of meer van de hierboven vermelde rechten uit te oefenen, kunt u een verzoek sturen naar MilkBE vzw, Kolonel Begaultlaan 1A bus 11, 3012 Leuven of via e-mail met in bijlage dit verzoek naar office&#64;milkbe.org. In dit verzoek moet duidelijk worden vermeld welk recht u wenst uit te oefenen en waarom. Het moet ook gedateerd en ondertekend zijn en vergezeld gaan van een digitaal gescande kopie van uw geldige identiteitskaart waaruit uw identiteit blijkt. Wij zullen u binnen een redelijke termijn op de hoogte stellen van de ontvangst van dergelijk verzoek. Indien de aanvraag gegrond blijkt te zijn, zullen wij deze zo spoedig als redelijkerwijs mogelijk is en uiterlijk dertig (30) dagen na ontvangst van de aanvraag inwilligen zonder verder tegenbericht. Indien de aanvraag ongegrond zou blijken te zijn, zullen wij u op de hoogte brengen van de weigering en de reden er van.</p>
        <p>Als u een klacht heeft over de verwerking van uw persoonsgegevens door ons, kunt u altijd contact met ons opnemen via het e-mailadres office&#64;milkbe.org. Blijft u ontevreden over ons antwoord, dan staat het u vrij om een klacht in te dienen bij de Belgische Privacycommissie. Ga voor meer informatie naar https://www.privacycommission.be.</p>
    </ng-container>
    <ng-container *ngIf="languageCode === 'fr'">
        <h2>DÉCLARATION DE CONFIDENTIALITÉ</h2>
        <h3>GÉNÉRALITÉS</h3>
        <p>La présente déclaration de confidentialité régit le traitement de vos données personnelles dans le cadre de votre utilisation de notre Site internet (le “Site”) et/ou de tous les services proposés ou liés (dénommés collectivement les “Services”).</p>
        <p>Ce traitement est effectué par l’asbl MilkBE, qui est responsable du traitement, et dont le siège est situé à 3012 Leuven, Kolonel Begaultlaan 1A boîte 11, et qui a pour numéro d’entreprise 0725.808.537, conformément à la législation applicable en matière de protection des données à caractère personnel, sauf mention contraire explicite, auquel cas ce n’est pas MilkBE, mais un tiers explicitement précisé, qui est responsable du traitement.</p>
        <p>La présente déclaration de confidentialité indique, entre autres, quelles sont les données collectées sur le Site par MilkBE, comment ces données sont utilisées par MilkBE, comment ces données sont protégées et quels sont vos droits personnels.</p>
        <p><b>En utilisant (continuant à utiliser) le présent Site et/ou les Services qui y sont proposés, vous confirmez avoir pris intégralement connaissance de la présente Déclaration de confidentialité et vous déclarez l’accepter intégralement.</b></p>
        <p>Si vous ne l’acceptez pas intégralement, quittez ce site internet de votre initiative et n’utilisez d’aucune façon ce Site et/ou les Services.</p>
        <p>MilkBE se réserve le droit de modifier (régulièrement) la Déclaration de confidentialité comme bon lui semble. Ces modifications, ajouts ou substitutions seront portés à votre connaissance via notre Site et/ou les Services, le cas échéant. Si vous n’acceptez pas ces modifications, ajouts ou substitutions, nous vous demandons d’envoyer un courriel à office&#64;milkbe.org.</p>
        <p>Si nous ne recevons aucune communication de votre part dans les cinq (5) jours ouvrables qui suivent la communication de ces modifications, ajouts ou substitutions via notre Site internet ou Services, vous êtes considéré comme ayant accepté toutes les modifications, substitutions et tous les ajouts. Tenez compte du fait que le retrait de votre accord des conditions peut impliquer que vous ne soyez plus en mesure d’utiliser en tout ou en partie le Site et/ou les Services.</p>
        <p>En cochant la case de participation privacy opt-in checkbox sur les formulaires du Site, vous déclarez avoir lu et accepté la présente déclaration de confidentialité et que vous êtes d’accord. Vous déclarez comprendre à quelles fins vos données personnelles sont traitées. Vous acceptez également le fait que votre poursuite de l’utilisation du Site et/ou des Services est considérée comme la poursuite de votre accord. Vous pouvez retirer votre accord à tout moment en envoyant une requête par courriel à office&#64;milkbe.org.</p>
        <p>Vous certifiez que les données que vous communiquez à MilkBE vous appartiennent, ou que vous avez l’autorisation de les utiliser et de les communiquer à MilkBE. MilkBE peut vous refuser en tout ou en partie l’accès à son(ses) Site(s) et/ou Services, de manière temporaire ou définitive, si vous communiquez des données falsifiées ou des données de tiers sans leur autorisation.</p>
        <p>Si un composant de notre Site ou de nos Services ne fonctionne pas comme prévu en termes de confidentialité, n’hésitez pas à contacter notre secrétariat: MilkBE asbl, Kolonel Begaultlaan 1A boîte 11, 3012 Leuven ou office&#64;milkbe.org ou encore le 016 30 07 70.</p>
        <br>
        <p><b>La présente déclaration de confidentialité a été actualisée pour la dernière fois le 03/03/2021.</b></p>
        <br>
        <h3>QUELLES SONT LES DONNÉES TRAITÉES ET POURQUOI LES TRAITONS-NOUS </h3>
        <p>MilkBE récolte des données afin de pouvoir travailler efficacement, de vous offrir la meilleure expérience de notre Site et de nos Services, pour entrer en contact avec vous, pour vous fournir les produits ou les services demandés ou pour lesquels vous vous êtes enregistré, pour vous informer sur notre offre de produits et services (comme des données sur les événements de MilkBE, etc.), pour personnaliser notre offre de produits et services et éventuellement à des fins de marketing direct.</p>
        <p>Vous nous communiquez directement certaines de ces données, notamment lorsque vous vous abonnez à un Service, lorsque vous nous contactez pour une question, etc. Une partie de ces données est obtenue en définissant de quelle manière vous gérez notre Site et nos Services, par exemple par l’utilisation de technologies telles que les cookies. Tenez donc compte du fait que MilkBE peut utiliser des ‘cookies’ ou des technologies similaires en tant que partie du Site et/ou des Services. Les cookies sont de petits fichiers texte insérés sur le disque dur d’un ordinateur, et qui contiennent certaines informations, parfois personnelles. Pour de plus amples informations sur notre utilisation de cookies, consultez notre Déclaration relative aux cookies.</p>
        <p>Lorsque vous utilisez le Site et/ou les Services, nous traitons des données personnelles à votre sujet (comme par exemple, mais pas de façon exhaustive et pas toujours), votre prénom, nom, fonction, adresse e-mail, numéro de téléphone et photo et ce systématiquement pour le délai de conservation utile. Ces données personnelles peuvent englober:</p>
        <img src="/assets/img/misc/cookie-policy.png" alt="cookies" width="750" height="233">
        <p>En principe, nous obtenons les données personnelles susmentionnées directement de votre part. Nous n’envoyons pas de données personnelles que vous fournissez via ce Site à des réseaux sociaux, sauf si vous donnez formellement votre accord. Outre les finalités susmentionnées, nous pouvons également traiter vos données personnelles:</p>
        <ul>
            <li>pour vous fournir des informations sur les produits et les services demandés de manière efficace et personnalisée, via le Site, par courriel, par téléphone ou via les réseaux sociaux.</li>
            <li>à des fins de marketing direct, à savoir pour pouvoir vous fournir une communication ciblée, des promotions, des offres et d’autres annonces émanant de nous ou de partenaires sélectionnés. Nous vous demanderons votre autorisation préalable pour cela.</li>
            <li>pour réaliser des analyses statistiques afin d’améliorer notre Site/et ou nos Services, ou pour développer de nouveaux produits ou services.</li>
            <li>pour fournir des informations à un organisme financier ou prestataire de services de paiement, afin de permettre à votre organisme financier ou prestataire de services de paiement de respecter ses obligations (légales).</li>
            <li>pour transmettre des informations à la police ou aux autorités légales, à titre de preuve d’un éventuel délit ou en cas de suspicion fondée d’agissements illégaux ou d’un abus commis par vous par le biais de votre enregistrement ou de votre utilisation du Site ou des Services.</li>
        </ul>
        <p>Si votre enregistrement sur le Site ou votre utilisation du Site ou des Services peut être considéré comme (a) une infraction aux conditions ou aux droits de propriété intellectuelle ou tout autre droit d’un tiers, (b) une menace pour la sécurité ou l’intégrité des Services, (c) un danger pour le Site ou les Services, nos systèmes ou les systèmes de nos sous-traitants suite à des virus, des chevaux de Troie, des spyware, des malware, ou toute autre forme de code malveillant, ou (d) illégal, illégitime, discriminatoire ou offensant, nous pouvons traiter vos données dans notre intérêt, l’intérêt de nos partenaires ou de tiers.</p>
        <h3>AVEC QUI PARTAGEONS-NOUS VOS DONNÉES ET POURQUOI</h3>
        <p>Nous n’envoyons pas vos données personnelles de manière identifiable à des tiers si cela n’est pas exigé pour l’octroi des Services, sans avoir obtenu votre autorisation préalable. Nous pouvons faire appel à des responsables du traitement externes pour vous proposer le Site et/ou les Services. Nous veillons à ce que ces responsables du traitement tiers ne traitent vos données personnelles qu’en notre nom, et selon nos instructions écrites. Nous garantissons que tous les responsables du traitement externes sont sélectionnés avec tout le soin nécessaire afin que nous puissions certifier la sécurité et l’intégrité de vos données personnelles. Nous pouvons transmettre des données anonymisées et/ou agrégées à des organisations tierces qui peuvent utiliser ces données pour améliorer des produits et des services et organiser le marketing, la présentation et la vente de produits et de services sur mesure.</p>
        <h3>OÙ TRAITONS-NOUS VOS DONNÉES</h3>
        <p>Nos responsables du traitement et nous-mêmes ne traiterons vos données personnelles identifiables qu’au sein de l’EER. Nous pouvons transmettre vos données anonymisées et/ou agrégées à des organisations en dehors de l’EER. En pareil cas, nous veillerons à obtenir des garanties suffisantes quant à la sécurité et à l’intégrité de vos données personnelles, et à ce que tous les droits relatifs aux données personnelles dont vous pourriez bénéficier dans le cadre du droit applicable soient garantis.</p>
        <p>En cas de transfert de vos données personnelles et/ou de données anonymisées et/ou agrégées à la  VSA (Google), le mécanisme de protection légal suivant est mis en oeuvre: Règles d’entreprise contraignantes.</p>
        <h3>COMMENT NOUS TRAITONS VOS DONNÉES</h3>
        <p>Nous mettons tout en oeuvre pour ne traiter que les données personnelles qui sont nécessaires à l’obtention des objectifs repris dans ces conditions d’emploi de Cookies. Nous traiterons vos données personnelles de manière légale, honnête et transparente. Nous mettons tout en oeuvre pour que les données personnelles soient actualisées et précises.</p>
        <p>Vos données personnelles ne sont traitées que si cela est nécessaire à l’obtention des objectifs repris dans nos conditions de vie privée et de Cookies, ou jusqu’à ce que vous retirez votre accord pour leur traitement. Tenez compte du fait que le retrait de votre autorisation pour le traitement peut impliquer que vous ne soyez plus en mesure d’utiliser le Site et/ou les Services en tout ou partie. Si vous vous êtes enregistré sur notre Site (si applicable), nous supprimerons vos  données personnelles si vous supprimez votre profil, à moins qu’une obligation légale ou réglementaire ou une contrainte judiciaire ou administrative nous en empêche.</p>
        <p>Nous prendrons les mesures techniques et organisationnelles adéquates pour garantir la sécurité de vos données personnelles contre tout accès non autorisé ou vol, perte non intentionnelle, manipulation ou destruction. L’accès par notre personnel ou le personnel de nos responsables du traitement externes est strictement limité et uniquement possible sur une base need-to-know, et est soumis à de strictes obligations de confidentialité. Sachez toutefois que notre souci de la sécurité et de la protection n’est qu’une obligation de moyen sans garantie.</p>
        <h3>VOS DROITS</h3>
        <p>Vous avez le droit de demander à consulter toutes les données personnelles que nous traitons à votre sujet. Les demandes de consultation visiblement introduites en vue de causer une surcharge ou une nuisance ne seront toutefois pas traitées.</p>
        <p>Vous avez le droit de demander à ce que toutes les données personnelles à votre sujet qui sont inexactes ou imprécises soient gratuitement corrigées. Si vous faites une telle demande, vous devez fournir la preuve que les données personnelles pour lesquelles vous demandez une correction sont inexactes.</p>
        <p>Vous avez le droit de retirer l’autorisation que vous avez donnée antérieurement pour le traitement de vos données personnelles. Vous pouvez retirer cette autorisation à tout moment en envoyant un courriel avec cette requête à office&#64;milkbe.org ou en supprimant votre profil (si applicable), ou si vous souhaitez retirer votre autorisation pour le traitement de vos données personnelles pour un certain Service, vous pouvez dans ce cas utiliser les possibilités de désabonnement prévues.</p>
        <p>Vous avez le droit de demander à ce que les données personnelles à votre sujet soient supprimées si elles ne sont plus nécessaires dans le cadre des objectifs exposés dans les présentes Conditions de confidentialité si vous retirez votre utilisation pour leur traitement. Vous devez toutefois tenir compte du fait qu’une telle demande de retrait sera évaluée par nos soins à la lumière des obligations légales ou réglementaires ou des contraintes judiciaires ou administratives qui peuvent nous empêcher de supprimer les données personnelles concernées. Plutôt que de demander la suppression de vos données personnelles, vous pouvez aussi nous demander de limiter le traitement de vos données personnelles si (a) vous contestez l’exactitude de ces données, (b) le traitement est illégal ou (c) les données ne sont plus nécessaires à l’obtention des objectifs cités, mais que vous en avez besoin pour vous défendre dans le cadre d’une procédure judiciaire.</p>
        <p>Vous avez le droit de vous opposer au traitement de vos données personnelles si vous êtes en mesure de démontrer que des raisons graves et justifiées en relation avec des circonstances particulières justifient une telle opposition. Si le traitement envisagé est à des fins de marketing direct, vous avez le droit de vous y opposer gratuitement et sans justification.</p>
        <p>Si vos données personnelles sont traitées sur base d’une autorisation ou d’un contrat et si le traitement est automatisé, vous avez le droit de recevoir les données personnelles que vous avez fournies à MilkBE dans un format structuré, communément utilisé et lisible par des machines et, si cela est techniquement possible, vous avez le droit de transmettre directement ces données à un autre prestataire de services. La faisabilité technique de cette opération est exclusivement évaluée par MilkBE.</p>
        <p>Si vous souhaitez introduire une requête pour exercer un ou plusieurs des droits susmentionnés, vous pouvez adresser votre demande à MilkBE asbl, Kolonel Begaultlaan 1A boîte 11, 3012 Leuven ou envoyer un courriel avec votre requête jointe à office&#64;milkbe.org. Dans votre requête, vous devez clairement mentionner le droit que vous souhaitez exercer et pourquoi. Votre requête doit en outre être datée et signée, et accompagnée d’une copie numérique de votre carte d’identité en cours de validité attestant de votre identité. Nous vous informerons dans un délai raisonnable de la réception de votre requête. Si votre requête est fondée, nous y accéderons aussi rapidement que possible et raisonnable et, au plus tard dans les trente (30) jours de la réception de votre requête, sauf avis contraire. Si votre requête n’est pas fondée, nous vous informerons de notre refus motivé.</p>
        <p>Si vous avez une plainte quant à la façon dont nous traitons vos données personnelles, vous pouvez nous contacter via l’adresse e-mail office&#64;milkbe.org. Si notre réponse ne vous satisfait pas, vous pouvez déposer plainte auprès de la Commission belge de la protection de la vie privée. Pour de plus amples informations, consultez https://www.privacycommission.be.</p>
    </ng-container>

</app-static-page-wrapper>
