// Angular
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-static-page-wrapper',
    templateUrl: './static-page-wrapper.component.html',
    styleUrls: ['./static-page-wrapper.component.scss']
})
export class StaticPageWrapperComponent {
    @Input() currentLanguage: 'nl' | 'fr' = 'nl';
    @Output() selectLanguage: EventEmitter<'nl' | 'fr'> = new EventEmitter();

    onSelectLanguage(lang: 'nl' | 'fr') {
        this.selectLanguage.emit(lang);
    }
}
