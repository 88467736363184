// models
import { PagingResponse, Search, TableActions } from 'src/app/models';

export interface AnalysesResult extends PagingResponse<Analyse> {
    data: Analyse[];
}

export interface AnalyseMinimal {
    id?: number;
    nameFr: string;
    isCluster: boolean;
    parameters: number[];
    isArchivable?: boolean;
    isEditable?: boolean;
    isDeletable?: boolean;
    isActivatable?: boolean;
    isArchived: boolean;
}

export interface AnalyseError {
    nameFr: string;
    nameNl: string;
    clusterCount: string;
    parameters: string;
    typeProductId: string;
    sizeId: string;
    laboId: string;
}

export interface Analyse extends AnalyseMinimal {
    nameNl: string;
    clusterCount: number;
    technicalContactId: number;
    laboId: number;
    typeProductId: number;
    sizeId: number;
    createTS?: string;
    createUsername?: string;
    editTS?: string;
    editUsername?: string;
    reportTime: number;
}

export interface FormattedAnalyse extends AnalyseMinimal {
    clusterCount?: string;
    nameNl: { type: string; nameNl: string; tile: string };
    laboId: string;
    technicalContactId?: string;
    actions: TableActions;
    routerLink: string;
    typeProductId: string;
    sizeId: string;
}

export interface AnalyseStatusResult extends PagingResponse<AnalyseStatus> {
    data: AnalyseStatus[];
}

export enum AnalyseStatus {
    ACTIVE = 'active',
    ARCHIVED = 'archived'
}

export interface AnalyseTypeResult extends PagingResponse<AnalyseType> {
    data: AnalyseType[];
}

export interface AnalyseType {
    id: number;
    description: Type;
    typeProducts: {
        id: number;
        description: string;
    }[];
}

export enum Type {
    RMO = 'RMO',
    HOEVE = 'Hoeve',
    EINDPRODUCT = 'Eindproduct'
}

export interface AnalyseSizeResult extends PagingResponse<AnalyseSize> {
    data: AnalyseSize[];
}

export interface AnalyseSize {
    id: number;
    description: Size;
}

export enum Size {
    '50ML' = '50 ml',
    '60ML' = '60 ml',
    '250ML' = '250 ml'
}

export interface AnalysesSearch extends Search {
    name?: string;
    parameterName?: string;
    status?: ('active' | 'archived')[];
    cluster?: boolean[];
    typeProductIds?: number[];
    sizeIds?: number[];
    laboIds?: number[];
    technicalContactIds?: number[];
}
