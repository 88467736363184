import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpersService } from '../../../services/helpers.service';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {
    @Input() heads: {
        name: string;
        code: string;
        sortable?: boolean;
        optional?: boolean;
        default?: boolean;
        width?: string;
        minWidth?: string;
        fixed: boolean;
    }[];
    @Input() data: any;
    @Input() sort?: { code: string; dir: 'asc' | 'desc' };
    @Input() editing?: boolean = false;
    @Input() emptyState?: string;
    @Input() RPP?: number = 20;
    @Input() startRow?: number = 0;
    @Input() totalRows?: number = null;
    @Input() loading: boolean = true;
    @Input() ready: boolean = true;
    @Input() timeout: number = 0;
    @Input() notReady: boolean = false;
    @Input() exportLabel: string = '';
    @Input() exportBusy: boolean = false;
    @Input() canAddRows: boolean = false;
    @Input() selected: string = '';
    @Input() addRowFormSTR: any = {};
    @Input() classes: string = '';
    @Input() id: any = 'id';
    @Input() code: string = '';
    @Input() hasColumnOptions: boolean = false;
    @Input() userColumnOptions: any = {};
    @Input() parameter?: string = '';
    @Input() backToOverviewLink?: string = '';
    @Input() backToOverviewText?: string = '';

    @Output() userColumnOptionsChange = new EventEmitter();
    @Output() sortChange = new EventEmitter();
    @Output() clickData = new EventEmitter();
    @Output() clickAction = new EventEmitter();
    @Output() startRowChange = new EventEmitter();
    @Output() clickExport = new EventEmitter();
    @Output() modalChange = new EventEmitter();
    @Output() linkClicked: EventEmitter<string> = new EventEmitter<string>();
    @Output() buttonClicked = new EventEmitter();
    // @Output() addRowToTable = new EventEmitter();

    clickable = false;
    helper: any = {};
    shimmerRows = [];
    popoverHelper: any = null;
    loadingTimedOut: boolean = false;
    loadingTimeout: any = null;
    columnOptions: any = null;
    localHeads: any = null;
    columnOptionsFromInput: boolean = false;

    /*
        -- column options --
        actions col required (can be empty)
        columnOptions: true
        code: used for structure {code: [options], ... in localstorage or @input}
        heads:
            - default = default visible on optional
    */

    constructor(public HelpersService: HelpersService, private ModalService: NgbModal) {}

    ngOnInit(): void {
        this.clickable = this.clickData.observers.length > 0;
        this.shimmerRows = Array(20 || this.RPP);
        if (this.timeout) {
            this.notReady = true;
            setTimeout(() => {
                this.notReady = false;
            }, this.timeout);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.loading) {
            if (changes.loading.currentValue === true) {
                this.loadingTimeout = setTimeout(() => {
                    this.loadingTimedOut = true;
                }, 800);
            } else {
                clearTimeout(this.loadingTimeout);
                this.loadingTimedOut = false;
            }
        }
        if (changes.heads) {
            this.initHeads();
        }
        if (changes.userColumnOptions) {
            this.initHeads();
        }
    }

    initHeads() {
        this.columnOptionsFromInput = this.userColumnOptionsChange.observers.length > 0;
        // column options
        if (this.code && this.hasColumnOptions) {
            const defaultOpts = [];
            for (let i = 0; i < this.heads.length; i++) {
                const head = this.heads[i];
                if (head.default && !head.fixed) {
                    defaultOpts.push(head.code);
                }
            }
            // let columnOptions = this.getColumnOptions();
            let columnOptions;

            if (!columnOptions) {
                columnOptions = defaultOpts;
                this.columnOptions = defaultOpts;
            } else {
                const opts = [];
                for (let i = 0; i < columnOptions.length; i++) {
                    const head = columnOptions[i];
                    if (
                        this.heads.find((item) => {
                            return item.code === head;
                        })
                    ) {
                        opts.push(head);
                    }
                }
                this.columnOptions = opts;
            }
        }
        this.setLocalHeads();
    }

    setLocalHeads() {
        if (!this.columnOptions) {
            this.localHeads = this.heads;
            return;
        }
        const heads = [];
        for (let i = 0; i < this.columnOptions.length; i++) {
            const option = this.columnOptions[i];
            const head = this.heads.find((item) => {
                return item.code === option;
            });
            if (head) {
                // equal width
                head.width = `${100 / this.columnOptions.length}%`;
                heads.push(head);
            }
        }
        for (let i = 0; i < this.heads.length; i++) {
            const head = this.heads[i];
            if (head.fixed) {
                heads.push(head);
            }
        }
        this.localHeads = heads;
    }

    identify(index, item) {
        return index;
    }

    getSortingClass(head: any) {
        if (head.sortable && this.sort.code === head.code) {
            return `sort-${this.sort.dir}`;
        } else if (head.sortable) {
            return 'sort-none';
        }
    }

    clickSort(head: any) {
        let result = this.sort;
        if (this.sort.code === head.code) {
            result.dir = this.sort.dir === 'asc' ? 'desc' : 'asc';
        } else {
            result = { code: head.code, dir: 'asc' };
        }
        this.sortChange.emit(result);
    }

    // openColumnOptionsModal() {
    //     const modalRef = this.ModalService.open(TableColumnOptionsModalComponent, {
    //         windowClass: 'table-column-options-modal',
    //         beforeDismiss: () => {
    //             return true;
    //         }
    //     });
    //     modalRef.componentInstance.options = [...this.columnOptions];
    //     modalRef.componentInstance.heads = [...this.heads];
    //     modalRef.result.then((next) => { }).finally(() => { });
    //     modalRef.componentInstance.confirmed.subscribe((next: any) => {
    //         this.storeColumnOptions(next);
    //     });
    // }

    // getColumnOptions() {
    //     if (this.columnOptionsFromInput) {
    //         return this.userColumnOptions;
    //     } else {
    //         return JSON.parse(localStorage.getItem('columnOptionsObject'));
    //     }
    // }

    // storeColumnOptions(options) {
    //     if (this.columnOptionsFromInput) {
    //         this.userColumnOptionsChange.emit(options);
    //     } else {
    //         localStorage.setItem('columnOptionsObject', JSON.stringify(options));
    //     }
    // }

    actionClick($event, action, $i, item) {
        $event.stopPropagation();
        $event.preventDefault();
        if (action.confirm) {
            this.helper.action = null;
            this.helper.actionItem = action;
            this.helper.confirm = $i;
        } else this.clickAction.emit({ item: item, action: action.code });
    }

    onButtonClicked(item) {
        this.buttonClicked.emit({ item });
    }

    onModelChange(item, key, value) {
        this.modalChange.emit({ item: item, key: key, value: value });
    }

    onLinkClicked($event, link) {
        $event.stopPropagation();
        $event.preventDefault();

        this.linkClicked.emit(link);
    }
}
