// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
// 3rd-party
import { Observable } from 'rxjs';

@Injectable()
export class SampleAuthorityGuardService  {
    constructor(private router: Router) {}

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (window.localStorage.getItem('companyTypeCode') === 'sample-authority') {
            return true;
        } else if (window.localStorage.getItem('companyTypeCode') === 'buyer') {
            return this.router.parseUrl('/my-sample-results');
        }

        return false;
    }
}
