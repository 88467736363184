import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslatePipe } from 'src/app/utils/pipes';

@Component({
    selector: 'app-confirm-action-modal',
    templateUrl: './confirm-action-modal.component.html',
    styleUrls: ['./confirm-action-modal.component.scss']
})
export class ConfirmActionModalComponent implements OnInit {
    @Output() confirmed = new EventEmitter<void>();
    @Output() cancelled = new EventEmitter<void>();
    @Input() confirmTitle?: string = '';
    @Input() confirmDescr?: string = '';
    @Input() confirmButton?: string = '';
    @Input() declineButton?: string = '';
    @Input() open: boolean = false;
    @Input() submitting: boolean = false;

    constructor(private TranslatePipe: TranslatePipe) { }

    ngOnInit(): void {
        if (!this.confirmTitle) {
            this.confirmTitle = this.TranslatePipe.transform('confirmaction_generic_title');
        }
        if (!this.confirmDescr) {
            this.confirmDescr = this.TranslatePipe.transform('confirmaction_generic_descr');
        }
    }

    cancel() {
        this.cancelled.emit();
    }

    confirm() {
        this.confirmed.emit();
    }
}
