// Angular
import { Injectable } from '@angular/core';
// local
import { RoleResult } from '../models';

@Injectable({
    providedIn: 'root'
})
export class UsersStoreService {
    roleResult: RoleResult = undefined;
}
