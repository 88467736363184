// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ngfModule } from 'angular-file';
// 3rd-party
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
// local
import {
    AvatarComponent,
    ButtonComponent,
    EmptyStateComponent,
    NgxDropzoneModule,
    SideNavigationComponent,
    TableComponent
} from './common';
import {
    FieldCheckboxesComponent,
    FieldCurrencyComponent,
    FieldDateComponent,
    FieldDropzoneComponent,
    FieldFileComponent,
    FieldHorizontalRadioComponent,
    FieldMultiInputComponent,
    FieldMultipleInputComponent,
    FieldMultipleTextInputsComponent,
    FieldMultiselectComponent,
    FieldPasswordCheckComponent,
    FieldRadiosComponent,
    FieldRichtextComponent,
    FieldSearchInputComponent,
    FieldSearchMultiselectComponent,
    FieldSearchPeriodComponent,
    FieldSelectComponent,
    FieldSingleCheckSmComponent,
    FieldSingleRadioSmComponent,
    FieldSingleCheckComponent,
    FieldSwitchComponent,
    FieldTextareaComponent,
    FieldTextComponent,
    FieldTimeComponent,
    FieldToggleComponent,
    FieldTypeaheadComponent
} from './fields';
import {
    AdvancedSearchComponent,
    FooterComponent,
    LoggedOutPageWrapperComponent,
    ModalFooterComponent,
    ModalSidePanelComponent,
    ModalTitleComponent,
    ModalWrapperComponent,
    PageWrapperComponent,
    PopoutPanelComponent,
    ProfileSectionComponent,
    SearchablePageWrapperComponent,
    SlideoutWrapComponent
} from './layout';
import {
    AlertComponent,
    CaptchaModelComponent,
    ConfirmActionComponent,
    ConfirmActionModalComponent,
    FileDisplayComponent,
    InlineSpinnerComponent,
    ModeSwitchComponent,
    OpenPurchaseOrderOverviewComponent,
    PaginationComponent,
    SampleDetailComponent,
    SampleEditDetailComponent,
    ShimmerComponent,
    SpinnerComponent
} from './misc';
import { UtilsModule } from '../utils/utils.module';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

@NgModule({
    imports: [
        NgbModule,
        CommonModule,
        FormsModule,
        NgxMaskDirective,
        NgxMaskPipe,
        UtilsModule,
        RouterModule,
        ngfModule,
        NgxDropzoneModule,
        RecaptchaModule,
        RecaptchaFormsModule
    ],
    declarations: [
        // common
        AvatarComponent,
        ButtonComponent,
        EmptyStateComponent,
        SideNavigationComponent,
        TableComponent,
        // fields
        FieldCheckboxesComponent,
        FieldCurrencyComponent,
        FieldDateComponent,
        FieldDropzoneComponent,
        FieldFileComponent,
        FieldHorizontalRadioComponent,
        FieldMultiInputComponent,
        FieldMultipleInputComponent,
        FieldMultipleTextInputsComponent,
        FieldMultiselectComponent,
        FieldPasswordCheckComponent,
        FieldRadiosComponent,
        FieldRichtextComponent,
        FieldSearchInputComponent,
        FieldSearchPeriodComponent,
        FieldSearchMultiselectComponent,
        FieldSelectComponent,
        FieldSingleCheckComponent,
        FieldSingleCheckSmComponent,
        FieldSingleRadioSmComponent,
        FieldSwitchComponent,
        FieldTextComponent,
        FieldTextareaComponent,
        FieldTimeComponent,
        FieldToggleComponent,
        FieldTypeaheadComponent,
        // layout
        AdvancedSearchComponent,
        FooterComponent,
        LoggedOutPageWrapperComponent,
        ModalFooterComponent,
        ModalSidePanelComponent,
        ModalTitleComponent,
        ModalWrapperComponent,
        PageWrapperComponent,
        PopoutPanelComponent,
        ProfileSectionComponent,
        SearchablePageWrapperComponent,
        SlideoutWrapComponent,
        // misc
        AlertComponent,
        CaptchaModelComponent,
        ConfirmActionComponent,
        ConfirmActionModalComponent,
        FileDisplayComponent,
        InlineSpinnerComponent,
        ModeSwitchComponent,
        OpenPurchaseOrderOverviewComponent,
        PaginationComponent,
        SampleDetailComponent,
        SampleEditDetailComponent,
        ShimmerComponent,
        SpinnerComponent,
        EmptyStateComponent
    ],
    exports: [
        // common
        ButtonComponent,
        // fields
        FieldCheckboxesComponent,
        FieldDateComponent,
        FieldHorizontalRadioComponent,
        FieldMultiInputComponent,
        FieldMultipleInputComponent,
        FieldMultipleTextInputsComponent,
        FieldMultiselectComponent,
        FieldPasswordCheckComponent,
        FieldRadiosComponent,
        FieldSearchMultiselectComponent,
        FieldSelectComponent,
        FieldSingleCheckComponent,
        FieldSwitchComponent,
        FieldTextComponent,
        FieldTextareaComponent,
        FieldToggleComponent,
        FieldSearchPeriodComponent,
        // layout
        LoggedOutPageWrapperComponent,
        ModalFooterComponent,
        ModalTitleComponent,
        ModalWrapperComponent,
        TableComponent,
        PageWrapperComponent,
        SearchablePageWrapperComponent,
        // misc
        AlertComponent,
        CaptchaModelComponent,
        ConfirmActionModalComponent,
        OpenPurchaseOrderOverviewComponent,
        SampleDetailComponent,
        SampleEditDetailComponent,
        SpinnerComponent,
        EmptyStateComponent
    ]
})
export class ComponentsModule {}
