<ng-container *ngIf="!ready">
    <app-spinner [centerVertically]="true"></app-spinner>
</ng-container>
<ng-container *ngIf="ready">
    <div class="translations-filters" id="top" [class.no-pointer]="saving?.length">
        <div class="row">
            <div class="col-2">
                <app-field-text
                    [model]="FILTERS.term" 
                    (modelChange)="FILTERS.term = $event;" 
                    [label]="''"
                    [instant]="true"
                    [typeClass]="'type--search'"
                    [placeholder]="'filter code...'">
                </app-field-text>
            </div>
            <!-- <div class="col-8 d-flex align-items-end">
                <app-field-single-check
                    [model]="FILTERS.empty" 
                    (modelChange)="FILTERS.empty = $event;" 
                    [title]="'all languages empty (new records)'">
                </app-field-single-check>
            </div> -->
        </div>
    </div>
    <table class="translations-table">
        <tr>
            <th style="min-width: 400px; max-width: 400px;">code</th>
            <th [width]="thWidth" *ngFor="let lang of languages">{{lang}}</th>
        </tr>
        <tr *ngFor="let string of strings | callback: filterStrings; index as $i" [class.saving]="indexIsSaving($i)" class="saving--pre">
            <td style="min-width: 400px; max-width: 400px;">
                <div class="code" [title]="string.string">{{string.string}}</div>
            </td>
            <td *ngFor="let lang of languages">
                <input type="text" [(ngModel)]="string[lang]" [class.translation-empty]="!string[lang]" [class.translation-filled]="string[lang]" (keydown.enter)="$event.target.blur()" (blur)="indexIsChanged($i) && saveIndex($i)" (ngModelChange)="changeIndex($i)">
            </td>
        </tr>
    </table>
</ng-container>

<div (click)="toTop()" id="up" [class.shown]="helper.showUp">UP</div>