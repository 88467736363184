<app-static-page-wrapper
    [currentLanguage]="languageCode"
    (selectLanguage)="onSelectLanguageCode($event)"
>
    <ng-container *ngIf="languageCode === 'nl'">
        <h2>GEBRUIKSVOORWAARDEN</h2>
        <p>Deze website en de erop aangeboden diensten worden beheerd door MilkBE vzw, met zetel te 3012 Leuven aan de Kolonel Begaultlaan 1A bus 11 en met ondernemingsnummer 0725.808.537.</p>
        <p><b>Door gebruik te (blijven) maken van deze website en de erop aangeboden diensten, bevestigt u integraal kennis te hebben genomen van deze gebruiksvoorwaarden, de Privacyverklaring en de Cookieverklaring en hiermee integraal akkoord te gaan.</b></p>
        <p>Indien u hiermee niet integraal akkoord gaat, verlaat u nu op eigen initiatief de website en maakt u op geen enkel wijze gebruik van deze website en/of de erop aangeboden diensten.</p>
        <p>MilkBE mag de website naar eigen inzicht en op ieder door hem gewenst moment (laten) veranderen of beëindigen, met of zonder voorafgaande verwittiging. MilkBE is niet aansprakelijk voor de gevolgen van verandering of beëindiging.</p>
        <p>U mag op uw eigen website een link aanbrengen naar de MilkBE-website. De webpagina waarop de link staat, moet daarbij volledig verdwijnen en het URL-adres van de MilkBE-website moet daarbij duidelijk zichtbaar zijn. MilkBE behoudt zich het recht voor om u de toestemming te ontzeggen de website te gebruiken en/of van bepaalde diensten die op de website zijn aangeboden, gebruik te maken. In aansluiting daarop kan MilkBE de toegang tot de website monitoren.</p>
        <p>MilkBE stelt de gegevens op de website uitsluitend ter beschikking voor informatieve doeleinden. Ongeautoriseerd of oneigenlijk gebruik van de website of de inhoud daarvan kan een inbreuk op intellectuele eigendomsrechten, regelgeving m.b.t. privacy, publicatie en/of communicatie in de breedste zin van het woord opleveren. Bij miskenning door u van de intellectuele eigendomsrechten, andere rechten van MilkBE of derden, kan u door MilkBE of derden aangesproken worden de veroorzaakte schade te vergoeden en/of MilkBE te vrijwaren tegen enige aanspraken of vorderingen van derden als gevolg van de inbreuken.</p>
        <p>U verbindt zich er o.m. toe om:</p>
        <ul>
            <li>de informatie niet op een onrechtmatige, onwettelijke manier te gebruiken;</li>
            <li>geen zodanig gebruik te maken van de website waardoor de website wordt beschadigd, vervormd, onderbroken, stopgezet of minder efficiënt wordt gemaakt;</li>
            <li>de website niet te gebruiken voor transmissie of posting van computervirussen, voor transmissie of posting van illegaal of onrechtmatig materiaal of van materiaal dat op enige wijze ongepast is (met inbegrip van, maar niet beperkt tot, materiaal met een lasterlijk, obsceen of bedreigend karakter);</li>
            <li>de website niet zodanig te gebruiken dat er een inbreuk wordt gepleegd op de rechten van een natuurlijke persoon, rechtspersoon of vereniging, zoals onder meer, doch niet beperkt tot de rechten van privacy en intellectuele eigendom;</li>
            <li>de website niet te gebruiken voor posting en transmissie van materiaal met promotionele of publicitaire doeleinden zonder voorafgaandelijke toestemming van MilkBE, behalve als daarom verzocht werd door de ontvanger.</li>
        </ul>
        <p>MilkBE tracht de informatie op de website voortdurend juist en actueel te houden en is voor een deel van de basisgegevens afhankelijk van derden. MilkBE kan in geen enkel geval aansprakelijk gesteld worden voor eventuele fouten, onvolledigheden of onnauwkeurigheden die zich zouden voordoen, noch voor verouderde gegevens.</p>
        <p>De samensteller van deze website is als enige aansprakelijk voor de inhoud ervan. De Europese Commissie is niet aansprakelijk voor het eventuele gebruik van de vermelde informatie.</p>
        <p>Op eenvoudig verzoek (via de onderstaande contactgegevens) van wie hiertoe enig wettelijk belang kan aantonen, zullen in voorkomend geval zo spoedig mogelijk de nodige aanvullingen of correcties aangebracht worden.</p>
        <p>MilkBE kan evenmin aansprakelijk gesteld worden indien zich, door het gebruik van de website of de erin opgenomen links, bij de raadpleger schade zou voordoen van welke aard ook. Verbinding naar andere, externe informatiebronnen wordt louter informatief gelegd en houdt geen enkel standpunt van MilkBE in tegenover de inhoud of het bestaan ervan.</p>
        <p>De website kan verwijzingen (bijvoorbeeld door middel van een hyperlink, banner of button) bevatten naar andere websites die betrekking hebben op een specifiek aspect van deze website of door derden geplaatst. Dit betekent niet automatisch dat MilkBE verbonden is aan deze andere websites of de eigenaren ervan. MilkBE is dan ook niet verantwoordelijk voor de naleving van de privacywetgeving door deze derden. Ook voor bijdragen, geplaatst door derden kan MilkBE niet verantwoordelijk gehouden worden. Gebruik hiervan is steeds op eigen risico.</p>
        <p>De website kan gebruik maken van diensten van externe service providers, cookies, webbeacons of gelijkaardige technologieën. Voor meer informatie hierover kan u de Privacyverklaring en de Cookieverklaring op deze website raadplagen. MilkBE is niet verantwoordelijk voor de naleving van de privacywetgeving door deze derden.</p>
        <p>Indien u informatie, zoals afbeeldingen of andere bestanden, naar of via de website verzendt, verklaart u eigenaar te zijn van alle intellectuele eigendomsrechten, auteursrechten, recht op afbeelding, naburige rechten en desgevallend andere rechten (ongeacht of deze geregistreerd zijn of niet) die hierop van toepassing zijn. U verleent hierop vanaf het moment van verzenden aan MilkBE:</p>
        <ul>
            <li>het onvoorwaardelijke gebruiksrecht;</li>
            <li>het recht op reproductie;</li>
            <li>het recht tot bewerking en vertaling;</li>
            <li>het recht op mededeling aan het publiek.</li>
        </ul>
        <p>De afstand van bovengenoemde rechten gebeurt kosteloos, tenzij uitdrukkelijk anders overeengekomen. MilkBE is vanaf het moment van overdracht gerechtigd om de overgedragen informatie (met uitsluiting van persoonsgegevens, zie Privacyverklaring) te gebruiken voor  marketingdoeleinden. Tevens vrijwaart u MilkBE voor alle mogelijke vormen van aansprakelijkheid met betrekking tot het gebruik van deze informatie, afbeeldingen of andere bestanden.</p>
        <p>Alleen het Belgisch recht is van toepassing op geschillen in verband met de website. Alleen de rechtbank van Leuven is bevoegd voor de behandeling van eventuele geschillen.</p>
        <p>Hebt u nog vragen waarop u geen antwoord hebt gevonden, neem dan contact op met het secretariaat: MilkBE vzw, Kolonel Begaultlaan 1A bus 11, 3012 Leuven of office&#64;milkbe.org of 016 30 07 70.</p>
        <p><b>Deze gebruiksvoorwaarden werden laatst bijgewerkt op 3/03/2021.</b></p>
    </ng-container>
    <ng-container *ngIf="languageCode === 'fr'">
        <h2>CONDITIONS D’UTILISATION</h2>
        <p>Ce site et les services qui y sont proposés sont gérés par MilkBE asbl dont le siège est situé à 3012 Leuven, Kolonel Begaultlaan 1A boîte 11, et qui a pour numéro d’entreprise 0725.808.537.</p>
        <p><b>En utilisant (continuant à utiliser) ce Site et/ou les Services qui y sont proposés, vous confirmez avoir pris intégralement connaissance des présentes conditions d’utilisation, de la Déclaration de confidentialité et de la Déclaration relative aux Cookies, et vous déclarez les accepter intégralement.</b></p>
        <p>Si ce n’est pas le cas, quittez ce site de votre propre initiative et ne l’utilisez d’aucune façon.</p>
        <p>MilkBE peut à tout moment, de sa propre initiative, (faire) modifier ou clôturer ce site, avec ou sans avertissement préalable. MilkBE ne peut être tenu responsable des conséquences de ces changements ou de cette clôture.</p>
        <p>Vous pouvez insérer, sur votre propre site, un lien vers le Site MilkBE. La page web sur laquelle figure le lien, doit entièrement disparaître et l’adresse URL du Site MilkBE doit être bien visible. MilkBE se réserve le droit de vous refuser l’utilisation du site et/ou de certains services proposés sur le site. Dans ce cadre, MilkBE peut contrôler l’accès au site.</p>
        <p>Les données présentées sur le Site par MilkBE sont fournies à des fins purement informatives. Une autorisation non autorisée ou impropre du Site ou de son contenu peut constituer une infraction aux droits de propriété intellectuelle, à la réglementation relative à la vie privée, à la publication et/ou à la communication au sens le plus large. En cas de non-respect des droits de propriété intellectuelle, d’autres droits de MilkBE ou de tiers, vous pouvez être tenu pour responsable par MilkBE ou des tiers de l’indemnisation des dommages engendrés et/ou d’exonérer MilkBE de toute responsabilité ou créance de tiers découlant de ces infractions.</p>
        <p>Vous vous engagez à:</p>
        <ul>
            <li>ne pas utiliser les informations de manière illégitime ou illégale;</li>
            <li>ne pas utiliser le site d’une manière qui risque de l’endommager, le déformer, l’interrompre, l’arrêter ou le rendre moins efficace;</li>
            <li>ne pas utiliser le site pour la transmission ou la diffusion de virus informatiques, de matériel illégal, illégitime ou inadéquat d’une quelconque façon (en ce compris, mais ne s’y limitant pas, tout matériel présentant des caractéristiques outrageantes, obscènes ou menaçantes);</li>
            <li>ne pas utiliser le site en infraction aux droits d’un personne physique, morale ou d’une association, en ce compris mais ne s’y limitant pas, les droits relatifs à la vie privée et à la propriété intellectuelle;</li>
            <li>ne pas utiliser le site pour envoyer ou transmettre du matériel publicitaire ou promotionnel sans avoir obtenu l’accord préalable de MilkBE, sauf si cela a été demandé par le destinataire.</li>
        </ul>
        <p>MilkBE met tout en oeuvre pour veiller à ce que les informations présentées sur le site soient actualisées et correctes en permanence, et dépend pour cela en partie de données de base de tiers. MilkBE ne peut en aucun cas être tenu responsable d’erreurs, d’incomplétudes ou d’imprécisions éventuelles, ni de données désuètes.</p>
        <p>L’auteur de ce site est le seul responsable de son contenu. La Commission européenne ne peut être tenue responsable de l’utilisation éventuelle des informations mentionnées.</p>
        <p>Sur simple demande (via les coordonnées ci-dessous) de toute personne pouvant attester légalement de son intérêt, les corrections ou ajouts seront apportés dès que possible si nécessaire.</p>
        <p>MilkBE ne peut être tenu responsable de tout dommage de quelque nature que ce soit chez l’utilisateur induit par l’utilisation du site ou des liens qui y figurent. Les liens vers d’autres sources d’information externes sont purement informatifs et ne représentent pas le point de vue MilkBE quant à leur contenu ou leur existence.</p>
        <p>Le site peut comporter des renvois (par exemple par le biais d’un hyperlien, d’une bannière ou d’un bouton) vers d’autres sites qui ont trait à un aspect spécifique de ce site ou qui ont été insérés par des tiers. Cela ne signifie pas automatiquement que MilkBE soit lié à ces autres sites web ou à leurs propriétaires. Dès lors, MilkBE ne peut être tenu responsable du respect de la réglementation relative à la vie privée par ces tiers. MilkBE ne peut pas non plus être tenu responsable des contributions insérées par des tiers. Leur utilisation se fait toujours à vos propres risques.</p>
        <p>Le site peut utiliser les services de prestataires de services externes, des cookies, des balises internet ou des technologies similaires. Pour de plus amples informations à ce sujet, consultez la Déclaration de confidentialité et la Déclaration relative aux Cookies sur ce site. MilkBE ne peut être tenu responsable du respect de la réglementation relative à la vie privée par ces tiers.</p>
        <p>Si vous envoyez des informations, comme des illustrations ou d’autres fichiers, vers ou via le site, vous vous déclarez propriétaire de tous les droits de propriété intellectuelle, droits d’auteur, droits à l’image, droits voisins et aux autres droits éventuels (qu’ils soient enregistrés ou non) applicables. Dès le moment de l’envoi, vous accordez à MilkBE:</p>
        <ul>
            <li>le droit d’utilisation inconditionnel;</li>
            <li>le droit de reproduction;</li>
            <li>le droit de traitement de traduction;</li>
            <li>le droit de communication au public.</li>
        </ul>
        <p>La renonciation aux droits susmentionnés est gratuite, sauf convention contraire formelle. Dès le moment du transfert, MilkBE a le droit d’utiliser les informations transmises (à l’exception des données personnelles, voir la Déclaration sur la confidentialité) à des fins de marketing. Vous exonérez en outre MilkBE de toute forme de responsabilité relative à l’utilisation de ces informations, illustrations ou autres fichiers.</p>
        <p>Seul le droit belge est applicable aux litiges relatifs au site. Seul le tribunal de Leuven est compétent pour trancher les litiges éventuels.</p>
        <p>Si vous avez d’autres questions restées sans réponse, veuillez contacter le secrétariat: MilkBE asbl, Kolonel Begaultlaan 1A boîte 11, 3012 Leuven ou office&#64;milkbe.org ou le 016 30 07 70.</p>
        <p><b>Les présentes conditions d’utilisation ont été actualisées pour la dernière fois le 3/03/2021.</b></p>
    </ng-container>
</app-static-page-wrapper>
