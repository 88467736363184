// Angular
import { Injectable } from '@angular/core';
// 3rd-party
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
// local
import { DefaultService } from '../utils/api';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private user$ = new BehaviorSubject<object>(null);

    constructor(private defaultService: DefaultService) {}

    public fetchUser(): any {
        return this.defaultService.userMe().pipe(
            tap((next: any) => {
                this.setUser(next);
            })
        );
    }

    // public updateTableConfig(value, code) {
    //     const table_config = { ...this.getUserValue().table_config };
    //     table_config[code] = value;
    //     this.setUser({ ...this.getUserValue(), table_config: table_config });
    //     return this.DefaultService.userUpdateTableConfig({ table_config: table_config }).pipe(
    //         tap((next) => {
    //             // this.setUser({ ...this.getUserValue(), table_config: table_config });
    //         })
    //     );
    // }

    public logout() {
        const languageCode = this.getUserValue()?.language ?? 'nl';
        this.defaultService.userLogout().subscribe(() => {
            window.localStorage.removeItem('companyTypeCode');
            window.location.href = `/${languageCode}/login`;
        });
    }

    public onUnauth() {
        const languageCode = this.getUserValue()?.language ?? 'nl';
        window.localStorage.removeItem('companyTypeCode');
        window.location.href = `/${languageCode}/login`;
    }

    public getUserValue(): any {
        return this.user$.getValue() || null;
    }

    public getUser(): Observable<any> {
        return this.user$.asObservable();
    }

    private setUser(user: any) {
        this.user$.next(user);
    }
}
