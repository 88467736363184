// models
import { PagingResponse } from 'src/app/models';

export interface PlanningResult extends PagingResponse<Planning> {
    data: Planning[];
    planningInfo: PlanningInfo;
}

export interface PlanningInfo {
    editTS: string;
    editUsername: string;
}

export interface PlanningMinimal {
    id?: number;
    analysisId?: number;
    sampleAuthorityId?: number;
    sampleAuthority: string;
    isArchived: boolean;
}

export interface Planning extends PlanningMinimal {
    analysis?: string;
    type?: string;
    clusterCount?: number;
    amount_1: PlanningMonth;
    amount_2: PlanningMonth;
    amount_3: PlanningMonth;
    amount_4: PlanningMonth;
    amount_5: PlanningMonth;
    amount_6: PlanningMonth;
    amount_7: PlanningMonth;
    amount_8: PlanningMonth;
    amount_9: PlanningMonth;
    amount_10: PlanningMonth;
    amount_11: PlanningMonth;
    amount_12: PlanningMonth;
}

export interface PlanningDTO {
    id: number;
    amount_1: number;
    amount_2: number;
    amount_3: number;
    amount_4: number;
    amount_5: number;
    amount_6: number;
    amount_7: number;
    amount_8: number;
    amount_9: number;
    amount_10: number;
    amount_11: number;
    amount_12: number;
}

export interface PlanningMonth {
    amount: number;
    editable: boolean;
}

export interface FormattedPlanning extends PlanningMinimal {
    analysis?: { type: 'tile'; analysis: string; tile?: string };
    type?: { type: 'default'; value: string; placeholder: string };
    clusterCount?: { type: 'default'; value: string; placeholder: string; classList?: string };
    amount_1: { type: 'object'; object: PlanningMonth; classList: string; changed?: boolean; error?: string };
    amount_2: { type: 'object'; object: PlanningMonth; classList: string; changed?: boolean; error?: string };
    amount_3: { type: 'object'; object: PlanningMonth; classList: string; changed?: boolean; error?: string };
    amount_4: { type: 'object'; object: PlanningMonth; classList: string; changed?: boolean; error?: string };
    amount_5: { type: 'object'; object: PlanningMonth; classList: string; changed?: boolean; error?: string };
    amount_6: { type: 'object'; object: PlanningMonth; classList: string; changed?: boolean; error?: string };
    amount_7: { type: 'object'; object: PlanningMonth; classList: string; changed?: boolean; error?: string };
    amount_8: { type: 'object'; object: PlanningMonth; classList: string; changed?: boolean; error?: string };
    amount_9: { type: 'object'; object: PlanningMonth; classList: string; changed?: boolean; error?: string };
    amount_10: { type: 'object'; object: PlanningMonth; classList: string; changed?: boolean; error?: string };
    amount_11: { type: 'object'; object: PlanningMonth; classList: string; changed?: boolean; error?: string };
    amount_12: { type: 'object'; object: PlanningMonth; classList: string; changed?: boolean; error?: string };
    total: { type: 'object'; object: PlanningMonth; classList: string };
}

export interface SampleTakersResult extends PagingResponse<SampleTaker> {
    data: SampleTaker[];
}

export enum SampleTaker {
    MCCVlaanderen = 'MCC Vlaanderen',
    ComiteDuLait = 'Comite du lait',
    total = 'total'
}
