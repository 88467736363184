import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
    @Input() disabled?: boolean = false;
    @Input() inputWidth?: string; // login button moest volledig plaats innemen
    @Input() submitting?: boolean = false;
    @Input() classes: string = 'primary-default';
    @Input() icon?: string = '';
    @Input() iconSize?: string = '16px';
    @Input() text: string = '';
    @Input() linkHref: string = '';
    @Input() linkTarget: string = '';
    @Output() emitClick = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}
}
