import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { APP_CONFIG } from './constants';

if (environment.production) {
    enableProdMode();
}

fetch('/api/config')
    .then((response) => response.json())
    .then((res) => {
        platformBrowserDynamic([{ provide: APP_CONFIG, useValue: res }])
            .bootstrapModule(AppModule)
            .catch((err) => console.error(err));
    });
