// Angular
import { NgModule } from '@angular/core';
// local
import {
    AnalyseLaboPipe,
    AnalyseParameterPipe,
    AnalyseTechnicalContactsPipe,
    AnalyseSizePipe,
    AnalyseTypePipe,
    DisplayPipe,
    EnumToDropdownOptionsPipe,
    LaboToDropdownOptionsPipe,
    LaboToTechnicalContactsDropdownOptionsPipe,
    ParametersToCodePipe,
    ParametersToKeyValuePipe,
    ParameterUnitPipe,
    TranslatePipe,
    UnitToDropdownOptionsPipe,
    PlanningFilter,
    CountryPipe,
    LanguagePipe,
    ContactRolePipe,
    formatTsPipe,
    DateToMonthPeriod,
    SampleBuyerPipe,
    SampleProvincePipe,
    SampleWarehousePipe,
    LocalizedDatePipe,
    TitleCasePipe,
    objToParamsPipe,
    searchBodyPipe,
    objLenPipe
} from './pipes';
import { ClickOutsideDirective } from './directives';

@NgModule({
    declarations: [
        ClickOutsideDirective,
        ContactRolePipe,
        AnalyseLaboPipe,
        AnalyseParameterPipe,
        AnalyseTechnicalContactsPipe,
        AnalyseSizePipe,
        AnalyseTypePipe,
        DateToMonthPeriod,
        DisplayPipe,
        EnumToDropdownOptionsPipe,
        CountryPipe,
        LaboToDropdownOptionsPipe,
        LaboToTechnicalContactsDropdownOptionsPipe,
        LanguagePipe,
        LocalizedDatePipe,
        ParameterUnitPipe,
        ParametersToCodePipe,
        ParametersToKeyValuePipe,
        PlanningFilter,
        SampleBuyerPipe,
        SampleProvincePipe,
        SampleWarehousePipe,
        TitleCasePipe,
        objToParamsPipe,
        searchBodyPipe,
        TranslatePipe,
        UnitToDropdownOptionsPipe,
        formatTsPipe,
        objLenPipe
    ],
    exports: [
        ClickOutsideDirective,
        ContactRolePipe,
        AnalyseLaboPipe,
        AnalyseParameterPipe,
        AnalyseTechnicalContactsPipe,
        AnalyseSizePipe,
        AnalyseTypePipe,
        DateToMonthPeriod,
        DisplayPipe,
        EnumToDropdownOptionsPipe,
        CountryPipe,
        LaboToDropdownOptionsPipe,
        LaboToTechnicalContactsDropdownOptionsPipe,
        LanguagePipe,
        LocalizedDatePipe,
        ParameterUnitPipe,
        ParametersToCodePipe,
        ParametersToKeyValuePipe,
        PlanningFilter,
        SampleBuyerPipe,
        SampleProvincePipe,
        SampleWarehousePipe,
        TitleCasePipe,
        objToParamsPipe,
        searchBodyPipe,
        TranslatePipe,
        UnitToDropdownOptionsPipe,
        formatTsPipe,
        objLenPipe
    ]
})
export class UtilsModule {}
