<div class="pagination d-inline-flex align-items-center">
    <button [class.disabled]="startRow <= 0" (click)="changePage('prev')" class="prev d-flex"><i class="im-icon im-icon-chev-left"></i></button>
    <!-- start pages -->
    <button (click)="changePage(1)" [class.active]="currentPage === 1">{{pages[0]}}</button>
    <span class="fill" *ngIf="showFill(true)">...</span>
    <ng-container *ngFor="let i of pages">
        <button (click)="changePage(i)" [class.active]="currentPage === i" *ngIf="i != 1 && i != pages.length && truncate(i)">{{i}}</button>
    </ng-container>
    <span class="fill" *ngIf="showFill(false)">...</span>
    <button (click)="changePage(pages.length)" [class.active]="currentPage === pages.length" *ngIf="pages.length > 1">{{pages[pages.length - 1]}}</button>
    <!-- end pages -->
    <button [class.disabled]="startRow >= totalRows - RPP" (click)="changePage('next')" class="next d-flex"><i class="im-icon im-icon-chev-right"></i></button>
</div>