// Angular
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
// 3rd-party
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
// local
import { AuthenticationService } from '../services';
import { ConfigService, TranslatorService } from '../services';
import * as packageJson from '../../../package.json';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    version: string;
    constructor(
        private authenticationService: AuthenticationService,
        private configService: ConfigService,
        private router: Router,
        private toastr: ToastrService,
        private translatorService: TranslatorService
    ) {
        const packageJsonAny: any = packageJson;
        this.version =
            packageJsonAny?.build ||
            packageJsonAny.version ||
            packageJsonAny?.default?.build ||
            packageJsonAny?.default?.version;
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let clonedRequest = request.clone();

        const token = this.configService.getConfig().token;
        if (token) {
            clonedRequest = clonedRequest.clone({
                headers: clonedRequest.headers.append('captcha-token', token)
            });
            this.configService.setConfig({ token: null });
        }

        const token2 = this.configService.getConfig().token2;
        if (token2) {
            clonedRequest = clonedRequest.clone({
                headers: clonedRequest.headers.append('captcha-token-v2', token2)
            });
            this.configService.setConfig({ token2: null });
        }

        clonedRequest = clonedRequest.clone({
            headers: clonedRequest.headers.append('Accept-Language', this.translatorService.getCurrentLanguage())
        });

        if (clonedRequest.method == 'GET') {
            const versionParam = `${this.version}-${this.translatorService.getCurrentLanguage()}`;
            clonedRequest = clonedRequest.clone({
                setParams: {
                    v: versionParam
                }
            });
        }

        function failwhale(error) {
            const msg = `${error.error}`;
            if (document.getElementsByClassName('page') && document.getElementsByClassName('page').length) {
                document.getElementsByClassName('page')[0].innerHTML = msg;
            } else {
                document.getElementsByTagName('BODY')[0].innerHTML = msg;
            }
            // modals
            if (
                document.getElementsByClassName('modal-backdrop') &&
                document.getElementsByClassName('modal-backdrop').length
            ) {
                document.getElementsByClassName('modal-backdrop')[0].setAttribute('style', 'display:none !important');
            }
            if (document.getElementsByClassName('modal') && document.getElementsByClassName('modal').length) {
                document.getElementsByClassName('modal')[0].setAttribute('style', 'display:none !important');
            }
            if (document.getElementsByClassName('modal-open') && document.getElementsByClassName('modal-open').length) {
                document.getElementsByClassName('modal-open')[0].setAttribute('style', 'overflow:unset !important');
            }
            // footer
            if (document.getElementsByClassName('footer') && document.getElementsByClassName('footer').length) {
                document.getElementsByClassName('footer')[0].setAttribute('style', 'display:none !important');
            }
        }
        return next.handle(clonedRequest).pipe(
            catchError((error: HttpErrorResponse) => {
                switch (error.status) {
                    case 400:
                        // bad request / validation
                        if (error.error && error.error.details && typeof error.error.details == 'string') {
                            // this.toastr.error(error.error.details, 'Fout');
                        } else {
                            // this.toastr.error(
                            //     this.TranslatePipe.transform('toast_validation_error_msg'),
                            //     this.TranslatePipe.transform('toast_validation_error_title')
                            // );
                        }
                        break;
                    case 401:
                        // unauthorized
                        this.redirectToLogin();
                        break;
                    case 500:
                        // internal server error
                        failwhale(error);
                        break;
                    case 403:
                        // forbidden
                        failwhale(error);
                        break;
                    case 404:
                        // not found
                        failwhale(error);
                        break;
                }
                return throwError(error);
            })
        );
    }

    redirectToLogin() {
        this.authenticationService.onUnauth();
    }
}
