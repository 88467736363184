// import { HttpErrorResponse } from '@angular/common/http';
// import { ErrorHandler, Injectable, Injector } from '@angular/core';
// // import StackTrace from 'stacktrace-js';
// import * as StackTrace from 'stacktrace-js';
// import * as StackTraceGPS from 'stacktrace-js';

import { ErrorHandler, Injectable } from '@angular/core';
import { MonitoringService } from '../services/monitoring.service';

// @Injectable()
// export class GlobalErrorHandler implements ErrorHandler {
//     constructor(private injector: Injector) {}

//     handleError(error: Error | HttpErrorResponse) {
//         let message;
//         let stackTrace;

//         message = this.getClientMessage(error);
//         stackTrace = this.getClientStack(error);

//         if (location.hostname === 'localhost' || location.hostname === '127.0.0.1' || location.hostname === '') {
//             console.error(error);
//         } else {
//             StackTrace.fromError(error).then((stack) => {
//                 let prettyStack = message + '\n';
//                 stack.forEach((line) => {
//                     prettyStack += line;
//                     prettyStack += '\n';
//                 });
//                 console.error(prettyStack);
//             });
//         }
//     }

//     getClientMessage(error: Error): string {
//         return error.message ? error.message : error.toString();
//     }

//     getClientStack(error: Error): string {
//         return error.stack;
//     }
// }

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
    constructor(private MonitoringService: MonitoringService) {
        super();
    }
    handleError(error: Error) {
        this.MonitoringService.logException(error); // Manually log exception
        console.error(error);
    }
}
