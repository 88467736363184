// Angular
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// analyse
import { AnalysesStoreService } from '../modules/settings/analyses/services/analyses-store.service';
// local
import { TranslatePipe } from '../utils/pipes';
import { HelpersService } from './helpers.service';
import { ResourceService } from './resource.service';
import moment from 'moment';
import { AuthenticationService } from './authentication.service';
import { AnalyseStatus } from '../modules/settings/analyses/models';
import { UsersStoreService } from '../modules/users/services/users-store.service';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    searchNames: any = {};

    constructor(
        public translatePipe: TranslatePipe,
        public helpersService: HelpersService,
        public resourceService: ResourceService,
        private authenticationService: AuthenticationService,
        private analysesStoreService: AnalysesStoreService,
        private router: Router,
        private usersStoreService: UsersStoreService
    ) {}

    storeSearchName($event, code) {
        this.searchNames[$event.item.value] = $event.item.title;
    }

    getSearchNameById(id) {
        return this.searchNames[id];
    }

    async formatTags(SEARCHED: any, initialSEARCH: any) {
        const result = [];
        let searchNames;
        // const fetchAsyncKeys = ['pharmaceuticalForm', 'routeOfAdministration', 'atcCode'];
        const fetchAsyncKeys = [];
        for (var i = fetchAsyncKeys.length - 1; i >= 0; i--) {
            const item = fetchAsyncKeys[i];
            if (!SEARCHED[item] || this.searchNames[SEARCHED[item]]) {
                fetchAsyncKeys.splice(i, 1);
            }
            if (SEARCHED[item] && this.searchNames[SEARCHED[item]]) {
                result.push({
                    // title: this.TranslatePipe.transform(`tagname_${item}`) + `: ${this.searchNames[SEARCHED[item]]}`,
                    title: this.translatePipe.transform(`tagname_${item}`),
                    value: this.searchNames[SEARCHED[item]],
                    key: item
                });
            }
        }

        if (fetchAsyncKeys.length) {
            searchNames = await this.resourceService.getSearchNames({
                pharmaceuticalForm: SEARCHED.pharmaceuticalForm,
                routeOfAdministration: SEARCHED.routeOfAdministration,
                atcCode: SEARCHED.atcCode
            });
            for (const key in searchNames) {
                const value = searchNames[key];
                result.push({
                    // title: this.TranslatePipe.transform(`tagname_${key}`) + `: ${value}`,
                    title: this.translatePipe.transform(`tagname_${key}`),
                    value: value,
                    key: key
                });
                // this.storeSearchName({ item: { title: value, value: SEARCHED[key] } }, key);
                this.storeSearchName({ item: { title: value, value: SEARCHED[key] } }, key);
            }
        }
        for (const key in SEARCHED) {
            const value = SEARCHED[key];
            let title;
            if (value === '' || value === null || value === undefined) {
                continue;
            }
            switch (key) {
                // @pj after search display searched properties under search bar, here you can overwrite the value (for example when value is an ID -> map to resource) see examples in comment

                // case 'environment':
                //     if (value === 'all') {
                //         title = this.TranslatePipe.transform('misc_all');
                //     } else title = this.ResourceService.getResourceById('environment', value).title;
                //     break;
                case 'event':
                    title = this.resourceService.getResourceById('event_type', value).title;
                    break;
                case 'laboId':
                    title = this.analysesStoreService.labResult?.data?.find((lab) => lab.id === value)?.name ?? value;
                    break;
                case 'technicalContactId':
                    const technicalContact = this.analysesStoreService.labResult?.data
                        ?.find((results) => results.id === SEARCHED.laboId)
                        ?.technicalContacts?.find((technicalContact) => technicalContact.id === value);

                    title = technicalContact ? `${technicalContact.firstname} ${technicalContact.lastname}` : value;
                    break;
                case 'typeProductId':
                    this.analysesStoreService.typeResult?.data?.every((typeOptions) => {
                        title = typeOptions.typeProducts.find((option) => option.id === value)?.description ?? '';
                        return !title;
                    });
                    break;
                case 'sizeId':
                    title =
                        this.analysesStoreService.sizeResult?.data?.find((size) => size.id === value)?.description ??
                        value;
                    break;
                case 'cluster':
                    title = value ? this.translatePipe.transform('yes') : this.translatePipe.transform('no');
                    break;
                case 'status':
                    title =
                        value === AnalyseStatus.ACTIVE
                            ? this.translatePipe.transform('active')
                            : this.translatePipe.transform('archived');
                    break;
                case 'authority':
                    title =
                        this.usersStoreService.roleResult?.data?.find((role) => role.id === value)?.description ??
                        value;
                    break;
                case 'isActive':
                    title = value ? this.translatePipe.transform('active') : this.translatePipe.transform('inactive');
                    break;
                // case 'hasAttachment':
                // case 'isError':
                //     title = this.ResourceService.getYesNoOption(value).title;
                //     break;
                // case 'bounce_on_from':
                // case 'bounce_on_from':
                // case 'event_on_from':
                // case 'event_on_from':
                // case 'date_from':
                // case 'date_to':
                //     title = moment(value).format('DD-MM-YYYY');
                //     break;
                case 'term':
                    break;
                default:
                    title = value;
                    break;
            }
            // skip defaults
            if (initialSEARCH[key] && initialSEARCH[key] === value) {
                title = null;
            }
            if (title) {
                result.push({
                    title: this.translatePipe.transform(`tagname_${key}`),
                    value: title,
                    key: key
                });
            }
        }
        return result;
    }

    getSortingClass(code: string, SORT: any) {
        if (!SORT) return '';
        if (SORT.code == code) {
            return `sort-${SORT.dir}`;
        } else return '';
    }

    getCleanSearch(SEARCH: any) {
        const searchParams = Object.assign({}, SEARCH);
        for (const key in searchParams) {
            const value = searchParams[key];
            if (value === null || value === undefined || value === '') {
                delete searchParams[key];
            }
        }
        // search
        if (this.helpersService.objectIsEmpty(searchParams)) {
            return;
        }
        return searchParams;
    }

    getCleanSort(SORT: any, initialSort: any) {
        if (this.helpersService.objectIsEmpty(SORT) || (SORT.code == initialSort.code && SORT.dir == initialSort.dir)) {
            return;
        } else return SORT;
    }

    getCleanPage(startRow, RPP) {
        const page = (Math.ceil(startRow / RPP) + 1).toString();
        if (page == '1') {
            return;
        } else return page;
    }

    getSort(code: string, SORT: any, dir?: string) {
        let result = SORT;
        if (SORT.code === code) {
            result.dir = SORT.dir === 'asc' ? 'desc' : 'asc';
        } else {
            result = { code: code, dir: 'asc' };
        }
        if (dir) result.dir = dir;
        return result;
    }

    getSearchBody(SEARCH: any, SORT: any, RPP: number, startRow: number) {
        let orderBy = [`${SORT.code} ${SORT.dir}`];
        if (!SORT.code || !SORT.dir) {
            orderBy = null;
        }
        const result = {
            ...Object.assign({}, SEARCH),
            RPP: RPP,
            startRow: startRow,
            orderBy: orderBy
        };
        return result;
    }

    resetSearch() {
        this.router
            .navigate([], {
                queryParams: {
                    search: null,
                    page: null
                },
                queryParamsHandling: 'merge'
            })
            .then();
    }
}
