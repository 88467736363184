import { Injectable } from '@angular/core';
// labos
import { LabosResult } from '../../labos/models';
// local
import { AnalyseTypeResult, AnalyseSizeResult, AnalyseStatusResult } from '../models';

@Injectable({
    providedIn: 'root'
})
export class AnalysesStoreService {
    statusResult: AnalyseStatusResult = undefined;
    typeResult: AnalyseTypeResult = undefined;
    sizeResult: AnalyseSizeResult = undefined;
    labResult: LabosResult = undefined;
}
