import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthGuardService  {
    constructor(private AuthenticationService: AuthenticationService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        {
            if (route?.data?.portal && !this.AuthenticationService?.getUserValue()?.portal === route?.data?.portal) {
                this.AuthenticationService.onUnauth();
                return false;
            }

            return true;
        }
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (route?.data?.portal && !this.AuthenticationService?.getUserValue()?.portal === route?.data?.portal) {
            this.AuthenticationService.onUnauth();
            return false;
        }

        return true;
    }
}
