// Angular
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// 3rd-party
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    modalRoute$ = new BehaviorSubject<boolean>(false);
    hideNavigation$ = new BehaviorSubject<boolean>(false);
    hideFooter$ = new BehaviorSubject<boolean>(false);
    navCollapsed$ = new BehaviorSubject<boolean>(false);
    hideCaptcha$ = new BehaviorSubject<boolean>(false);

    private config: any = {
        captcha_public_key: '6LfTB6khAAAAAGR01H3df55bGNbwbkEwey7WDqP9'
    };

    constructor(public Route: ActivatedRoute) {}

    public getConfig(): any {
        return this.config;
    }

    public setConfig(config): any {
        this.config = { ...this.config, ...config };
    }

    // modal routes
    public setModalRoute(value: boolean) {
        this.modalRoute$.next(value);
    }
    public getModalRoute() {
        return this.modalRoute$;
    }

    // hide navigation
    public setHideNavigation(value: boolean) {
        this.hideNavigation$.next(value);
    }
    public getHideNavigation() {
        return this.hideNavigation$;
    }

    // hide footer
    public setHideFooter(value: boolean) {
        this.hideFooter$.next(value);
    }
    public getHideFooter() {
        return this.hideFooter$;
    }

    // nav collapsed
    public setNavCollapsed(value: boolean) {
        this.navCollapsed$.next(value);
    }
    public getNavCollapsed() {
        return this.navCollapsed$;
    }

    // hide captcha
    public setHideCaptcha(value: boolean) {
        this.hideCaptcha$.next(value);
    }
    public getHideCaptcha() {
        return this.hideCaptcha$;
    }
}
