<div class="d-flex flex-row align-items-center">
    <div
        [ngStyle]="{'width': size + 'px', 'height': size + 'px', 'font-size': fontSize + 'px', boxShadow: shadowSize ? '0px 3px ' + shadowSize + 'px 1px rgba(0, 0, 0, 0.3)' : 'none', backgroundColor: color ? color : '', cursor: clickable ? 'pointer' : 'default'}"
        class="avatar {{typeClass}} d-flex flex-row justify-content-center align-items-center"
        [class.member-avatar]="isMember"
    >
        <span *ngIf="user && (user.firstname || user.lastname)">{{initials}}</span>
    </div>
    <div *ngIf="title || subtitle" class="d-flex flex-column titles">
        <span class="title">{{title}}</span>
        <span class="sub">{{subtitle}}</span>
    </div>
</div>