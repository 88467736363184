// Angular
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DatePipe } from '@angular/common';
// 3rd-party
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
// local
import { LocalizedDatePipe, TranslatePipe } from '../utils/pipes';
import { ConfigService } from './config.service';

declare const grecaptcha: any;

@Injectable({
    providedIn: 'root'
})
export class HelpersService {
    params: any;
    busy$ = new BehaviorSubject<boolean>(false);

    constructor(
        private configService: ConfigService,
        private localizedDatePipe: LocalizedDatePipe,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        private translatePipe: TranslatePipe
    ) {
        route.queryParams.subscribe((next: any) => {
            this.params = next;
        });
    }

    public getParams() {
        return this.params;
    }
    public getParam(param: string | number): any {
        if (!param || !this.params[param]) {
            return '';
        } else return this.params[param.toString()];
    }
    public addParam(param: string, value: string | number) {
        if (!param) {
            return;
        } else {
            this.router.navigate([], {
                queryParams: {
                    [param]: value
                },
                queryParamsHandling: 'merge'
            });
        }
    }
    public removeParam(param: string | number) {
        if (!param) {
            return;
        } else {
            this.router.navigate([], {
                queryParams: {
                    [param]: null
                },
                queryParamsHandling: 'merge'
            });
        }
    }
    public removeUnnecessaryParamsForOverview(parameters: Params): void {
        for (const param in parameters) {
            switch (param) {
                case 'search':
                case 'sort':
                case 'page':
                case 'updateoverview':
                    break;
                default:
                    delete parameters[param];
                    break;
            }
        }
    }

    public reloadOverviewIfNecessary(parameters: Params): void {
        if (parameters.updateoverview) {
            this.router
                .navigate([], {
                    queryParams: {
                        updateoverview: null
                    },
                    queryParamsHandling: 'merge',
                    replaceUrl: true
                })
                .then();
        }
    }

    public resetStartRow(): void {
        this.router
            .navigate([], {
                queryParams: {
                    page: null
                },
                queryParamsHandling: 'merge'
            })
            .then();
    }
    // object is empty or only empty keys (null | undefined | '')
    public objectIsEmpty(object: any) {
        if (object.constructor != Object) return true;
        for (const key in object) {
            if (Object.prototype.hasOwnProperty.call(object, key)) {
                if (object[key] === null || object[key] === undefined || object[key] === '') {
                    continue;
                } else return false;
            }
        }
        return true;
    }
    public downloadBlob(blob: Blob, filename: string) {
        let a = document.createElement('a');
        document.body.appendChild(a);
        let blobUrl = window.URL.createObjectURL(blob);
        a.href = blobUrl;
        a.download = filename;
        a.click();
        setTimeout(function () {
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(a);
        }, 0);
    }
    public copyToClipboard(val: string, successMessage: string) {
        if (!val) {
            return;
        }
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        if (successMessage) {
            this.toastr.info('', successMessage);
        }
    }

    public isObj(item) {
        return typeof item === 'object' && !Array.isArray(item) && item !== null;
    }

    public toTop(anchor?: string, containerClass?: string) {
        let container: any = window;
        if (containerClass) {
            container = document.getElementsByClassName(containerClass)[0];
        }
        if (!anchor) {
            container.scrollTo(0, 0);
        } else {
            const el = document.getElementById(anchor);
            if (el) {
                const top = el.getBoundingClientRect().top + window.pageYOffset - 24;
                window.scrollTo({ top: top, behavior: 'smooth' });
            } else {
                window.scrollTo(0, 0);
            }
        }
    }

    public isDefined(item) {
        return item != undefined && item != null;
    }

    public getAndAmountOthersString(array, limit) {
        if (!limit) limit = 2;
        let result = '';
        for (let i = 0; i < array.length; i++) {
            const element = array[i];
            result += element.value;
            result += '; ';
            if (i >= limit - 1 && limit != array.length) {
                result +=
                    this.translatePipe.transform('misc_and_others_1') +
                    ' ' +
                    (array.length - limit) +
                    ' ' +
                    (array.length - limit === 1
                        ? this.translatePipe.transform('misc_and_others_2')
                        : this.translatePipe.transform('misc_and_others_2_plural'));
                break;
            }
        }
        return result;
    }

    public setBusy(val) {
        this.busy$.next(val);
    }

    public getBusy() {
        return this.busy$.asObservable();
    }

    public getUUID(parts?: number): string {
        parts = parts || 4;
        const stringArr = [];
        for (let i = 0; i < parts; i++) {
            // tslint:disable-next-line:no-bitwise
            const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            stringArr.push(S4);
        }
        return stringArr.join('-');
    }

    public isScrolledIntoView(el) {
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;

        var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
        return isVisible;
    }

    withCaptchaHeader(): Promise<any> {
        let that = this;
        return new Promise<void>((resolve, reject) => {
            if (this.configService.getConfig().token2) resolve();
            grecaptcha.ready(function () {
                grecaptcha
                    .execute(that.configService.getConfig().captcha_public_key, { action: 'submit' })
                    .then((token) => {
                        that.configService.setConfig({ token: token });
                        resolve(token);
                    })
                    .catch((err) => {
                        that.toastr.error(
                            that.translatePipe.transform('toast_validation_error_msg'),
                            that.translatePipe.transform('toast_validation_error_title')
                        );
                        reject();
                    });
            });
        });
    }

    createMonthPeriodFromDay(date: Date): string {
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        return `${this.localizedDatePipe.transform(firstDay, 'dd MMMM yyyy')} - ${this.localizedDatePipe.transform(
            lastDay,
            'dd MMMM yyyy'
        )}`;
    }

    public downloadLink(blobUrl: string, filename?: string) {
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.href = blobUrl;
        // a.download = filename;
        a.click();
        setTimeout(function () {
            // window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(a);
        }, 0);
    }
}
