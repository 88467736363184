<ng-container *ngIf="open">

    <div class="fade modal-backdrop modal-backdrop--route show"></div>

    <div class="d-block confirm-action-modal fade main-modal modal show modal--confirm" (click)="$event.stopPropagation(); $event.preventDefault(); cancel();">
        <div class="modal-dialog" (click)="$event.stopPropagation();$event.preventDefault();">
            <div class="modal-content">

                <div class="confirm-action-modal-inner">

                    <h3>{{confirmTitle}}</h3>
                    <div class="descr" *ngIf="confirmDescr" [innerHTML]="confirmDescr"></div>

                    <!-- foot -->
                    <div class="sep"></div>
                    <div class="foot">
                        <button (click)="$event.stopPropagation(); $event.preventDefault(); cancel()" [disabled]="submitting" class="button button-cancel">{{(declineButton || 'confirmaction_cancel_btn') | translate}}</button>
                        <button (click)="$event.stopPropagation(); $event.preventDefault(); confirm();" [disabled]="submitting" class="button button-confirm">{{(confirmButton || 'confirmaction_save_btn') | translate}}</button>
                    </div>
                    
                </div>

            </div>
        </div>
    </div>

</ng-container>