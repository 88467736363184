import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
    @Input() centerVertically?: boolean;
    @Input() after?: number;
    @Input() noMargin?: number;
    @Input() alignLeft?: boolean = false;

    timeout: boolean = false;

    constructor() { }

    ngOnInit() {
        if (this.after) {
            this.timeout = true;
            setTimeout(() => {
                this.timeout = false;
            }, this.after);
        }
    }
}
