// Angular
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, DatePipe, KeyValue } from '@angular/common';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';
// 3rd-party
import moment from 'moment';
import 'moment/locale/nl-be';
// modules
import { Parameter, Unit } from '../modules/settings/parameters/models';
import { Labo } from '../modules/settings/labos/models';
import { FormattedPlanning, SampleTaker } from '../modules/settings/planning/models';
// local
import { SearchService, TranslatorService } from '../services';
import { DropdownOption } from '../models';
import { HttpParams } from '@angular/common/http';

moment.locale('nl-be');

const keywordAt = 'om';
const keywordToday = 'vandaag';
const today = moment();

@Pipe({ name: 'formatTs' })
export class formatTsPipe implements PipeTransform {
    constructor(private TranslatePipe: TranslatePipe) {}
    transform(ts: any, format: string, unix?: boolean): string {
        let momentObj = !unix ? moment(ts) : moment.unix(ts);

        if (!ts || !format || !momentObj.isValid()) {
            return ts || '-';
        }
        switch (format) {
            case 'prettyTs':
                return momentObj.calendar(null, {
                    sameDay: `[${this.TranslatePipe.transform('misc_today')}]`,
                    nextDay: `[${this.TranslatePipe.transform('misc_tomorrow')}]`,
                    nextWeek: 'DD-MM-YYYY',
                    lastDay: `[${this.TranslatePipe.transform('misc_yesterday')}]`,
                    lastWeek: 'DD-MM-YYYY',
                    sameElse: 'DD-MM-YYYY'
                });
            case 'prettyDateTime':
                return momentObj.calendar(null, {
                    sameDay: `[${this.TranslatePipe.transform('misc_today')}] [${this.TranslatePipe.transform(
                        'misc_at'
                    )}] HH:mm`,
                    nextDay: `[${this.TranslatePipe.transform('misc_tomorrow')}] [${this.TranslatePipe.transform(
                        'misc_at'
                    )}] HH:mm`,
                    nextWeek: `D MMMM YYYY [${this.TranslatePipe.transform('misc_at')}] HH:mm`,
                    lastDay: `[${this.TranslatePipe.transform('misc_yesterday')}] [${this.TranslatePipe.transform(
                        'misc_at'
                    )}] HH:mm`,
                    lastWeek: `D MMMM YYYY [${this.TranslatePipe.transform('misc_at')}] HH:mm`,
                    sameElse: `D MMMM YYYY [${this.TranslatePipe.transform('misc_at')}] HH:mm`
                });
            default:
                break;
        }
        return moment(ts).format(format);
    }
}

@Pipe({
    name: 'localizedDate',
    pure: false
})
export class LocalizedDatePipe implements PipeTransform {
    constructor(private translateService: TranslatorService) {}

    transform(value: any, pattern: string = 'mediumDate'): any {
        const datePipe: DatePipe = new DatePipe(this.translateService.getLocale());
        return datePipe.transform(value, pattern);
    }
}

@Pipe({
    name: 'titleCase',
    pure: false
})
export class TitleCasePipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return value;
        return value[0].toUpperCase() + value.substr(1).toLowerCase();
    }
}

@Pipe({ name: 'display' })
export class DisplayPipe implements PipeTransform {
    transform(value: any, placeholder?): any {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            if (placeholder === '') {
                return '';
            }
            return placeholder ? placeholder : '-';
        }

        return value;
    }
}

@Pipe({ name: 'floatDisplay' })
export class floatDisplayPipe implements PipeTransform {
    transform(value: any, type?, fixed?: boolean): any {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            return '-';
        }

        if (!isNaN(parseFloat(value))) {
            let r = parseFloat(value).toString().replace(/\./g, ',');
            if (fixed) {
                r = value.toFixed(2).toString().replace(/\./g, ',');
            }
            if (type && type == 'thousandSeparator') {
                return r.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            } else {
                return r;
            }
        }

        return value;
    }
}

@Pipe({ name: 'numberDisplay' })
export class numberDisplayPipe implements PipeTransform {
    transform(value: any, type): any {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            return '-';
        }

        if (!isNaN(parseInt(value))) {
            let r = parseInt(value).toString();
            if (type && type == 'thousandSeparator') {
                return r.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            } else {
                return r;
            }
        }

        return value;
    }
}

@Pipe({ name: 'customCurrency' })
export class customCurrencyPipe implements PipeTransform {
    constructor(private cp: CurrencyPipe) {}
    transform(value: any, round?: boolean): any {
        if (value === 0 || value === '0') {
            return round ? '€ 0' : '€ 0,00';
        }
        if (!value) {
            return '-';
        } else {
            let returnValue = this.cp.transform(parseFloat(value).toString(), '€ ').replace(/,/g, '.');
            const n = returnValue.lastIndexOf('.');
            if (n >= 0 && returnValue.length) {
                returnValue = returnValue.substring(0, n) + ',' + returnValue.substring(n + 1);
            }
            if (round) {
                returnValue = returnValue.substring(0, returnValue.length - 3);
            }
            return returnValue;
        }
    }
}

@Pipe({ name: 'customPercentage' })
export class percentagePipe implements PipeTransform {
    transform(value: any): any {
        if (value === 0 || value === '0') {
            return '0%';
        }
        if (!value) {
            return '-';
        } else return value.toString().replace('.', ',') + '%';
    }
}

@Pipe({ name: 'translate' })
export class TranslatePipe implements PipeTransform {
    constructor(private TranslatorService: TranslatorService) {}
    public transform(key: string): string {
        return this.TranslatorService.getTranslation(key);
    }
}

@Pipe({ name: 'array' })
export class ArrayPipe implements PipeTransform {
    constructor() {}
    public transform(arr: any[]): string {
        if (!arr?.length) return '-';
        return arr.join(', ');
    }
}

@Pipe({
    name: 'safe'
})
export class SafePipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) {}

    public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        switch (type) {
            case 'html':
                return this.sanitizer.bypassSecurityTrustHtml(value);
            case 'style':
                return this.sanitizer.bypassSecurityTrustStyle(value);
            case 'script':
                return this.sanitizer.bypassSecurityTrustScript(value);
            case 'url':
                return this.sanitizer.bypassSecurityTrustUrl(value);
            case 'resourceUrl':
                return this.sanitizer.bypassSecurityTrustResourceUrl(value);
            default:
                throw new Error(`Invalid safe type specified: ${type}`);
        }
    }
}

@Pipe({
    name: 'callback',
    pure: false
})
export class callbackPipe implements PipeTransform {
    transform(items: any[], callback: (item: any, data?: any) => boolean, data?): any {
        if (!items || !callback) {
            return items;
        }
        return items.filter((item) => callback(item, data));
    }
}

type unit = 'bytes' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB';
type unitPrecisionMap = {
    [u in unit]: number;
};

const defaultPrecisionMap: unitPrecisionMap = {
    bytes: 0,
    KB: 0,
    MB: 1,
    GB: 1,
    TB: 2,
    PB: 2
};
@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {
    private readonly units: unit[] = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

    transform(bytes: number = 0, precision: number | unitPrecisionMap = defaultPrecisionMap): string {
        if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';

        let unitIndex = 0;

        while (bytes >= 1024) {
            bytes /= 1024;
            unitIndex++;
        }

        const unit = this.units[unitIndex];

        if (typeof precision === 'number') {
            return `${bytes.toFixed(+precision)} ${unit}`;
        }
        return `${bytes.toFixed(precision[unit])} ${unit}`;
    }
}

@Pipe({ name: 'vat' })
export class vatPipe implements PipeTransform {
    transform(value: any): any {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            return '-';
        }
        return `${value.substring(0, 4)}.${value.substring(4, 7)}.${value.substring(7, value.length)}`;
    }
}

@Pipe({ name: 'ven' })
export class venPipe implements PipeTransform {
    transform(value: any): any {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            return '-';
        }
        return `${value.substring(0, 1)}.${value.substring(1, 4)}.${value.substring(4, 7)}.${value.substring(
            7,
            value.length
        )}`;
    }
}

@Pipe({ name: 'objLen' })
export class objLenPipe implements PipeTransform {
    transform(obj: any): any {
        return Object.keys(obj)?.length;
    }
}

@Pipe({ name: 'isString' })
export class isStringPipe implements PipeTransform {
    transform(value: any): any {
        return (value && typeof value === 'string') || value instanceof String;
    }
}

@Pipe({ name: 'findInArray' })
export class findInArrayPipe implements PipeTransform {
    transform(value: any, array: any[], key?: string): any {
        if (!key) {
            return array.find((item) => {
                return item === value;
            });
        } else if (key) {
            return array.find((item) => {
                return item[key] === value;
            });
        }
    }
}

@Pipe({ name: 'parameterUnit' })
export class ParameterUnitPipe implements PipeTransform {
    transform(unitId: number, units: Unit[]): string {
        return units?.find((unit) => unit.id === unitId)?.description ?? '-';
    }
}

@Pipe({ name: 'analyseType' })
export class AnalyseTypePipe implements PipeTransform {
    transform(typeId: number, types: { title: string; options: DropdownOption<number>[] }[]): string {
        let title = '';

        types.every((typeOptions) => {
            title = typeOptions.options.find((option) => option.value === typeId)?.title ?? '';
            return !title;
        });

        return title ?? '-';
    }
}

@Pipe({ name: 'analyseSize' })
export class AnalyseSizePipe implements PipeTransform {
    transform(sizeId: number, sizes: DropdownOption<number>[]): string {
        return sizes?.find((size) => size.value === sizeId)?.title ?? '-';
    }
}

@Pipe({ name: 'analyseLabo' })
export class AnalyseLaboPipe implements PipeTransform {
    transform(laboId: number, labos: Labo[]): string {
        return labos?.find((labo) => labo.id === laboId)?.name ?? '-';
    }
}

@Pipe({ name: 'country' })
export class CountryPipe implements PipeTransform {
    transform(countryId: number, countries: DropdownOption<number>[]): any {
        return countries?.find((country) => country.value === countryId)?.title ?? '-';
    }
}

@Pipe({ name: 'language' })
export class LanguagePipe implements PipeTransform {
    transform(languageCode: 'nl' | 'fr', languages: DropdownOption<'nl' | 'fr'>[]): any {
        return languages?.find((language) => languageCode === language.value)?.title ?? '-';
    }
}

@Pipe({ name: 'analyseTechnicalContacts' })
export class AnalyseTechnicalContactsPipe implements PipeTransform {
    transform(technicalContactId: number, laboId: number, labos: Labo[]): string {
        if (!laboId) {
            return '-';
        }

        const labo = labos.find((labo) => labo.id === laboId);
        const technicalContact = labo?.technicalContacts.find(
            (technicalContact) => technicalContact.id === technicalContactId
        );

        return technicalContact ? `${technicalContact.firstname} ${technicalContact.lastname}` : '-';
    }
}

@Pipe({ name: 'analyseParameter' })
export class AnalyseParameterPipe implements PipeTransform {
    transform(parameterId: number, parameters: Parameter[]): string {
        return parameters?.find((parameter) => parameter.id === parameterId)?.nameNl ?? '-';
    }
}

@Pipe({ name: 'contactRole' })
export class ContactRolePipe implements PipeTransform {
    transform(roleId: number, roles: DropdownOption<number>[]): string {
        return roles.find((role) => role.value === roleId)?.title ?? '-';
    }
}

@Pipe({ name: 'unitsToDropdownOptions' })
export class UnitToDropdownOptionsPipe implements PipeTransform {
    transform(units: Unit[]): DropdownOption<number>[] {
        return units.map((unit) => {
            return {
                title: unit.description,
                value: unit.id
            };
        });
    }
}

@Pipe({ name: 'labosToDropdownOptions' })
export class LaboToDropdownOptionsPipe implements PipeTransform {
    transform(labos: Labo[]): DropdownOption<number>[] {
        return labos.map((labo) => {
            return {
                title: labo.name,
                value: labo.id
            };
        });
    }
}

@Pipe({ name: 'labosToTechnicalContactsDropdownOptions' })
export class LaboToTechnicalContactsDropdownOptionsPipe implements PipeTransform {
    transform(labos: Labo[], laboIds: number[]): DropdownOption<number>[] {
        if (!laboIds?.length) {
            return [];
        }

        const filteredLabos = labos.filter((labo) => laboIds.findIndex((id) => id === labo.id) > -1);
        if (!filteredLabos?.length) {
            return [];
        }

        let technicalContacts = [];
        filteredLabos.forEach((lab) => {
            technicalContacts = technicalContacts.concat(lab.technicalContacts);
        });

        return technicalContacts.map((technicalContact) => {
            return {
                title: `${technicalContact.firstname} ${technicalContact.lastname}`,
                value: technicalContact.id
            };
        });
    }
}

@Pipe({ name: 'parametersToKeyValue' })
export class ParametersToKeyValuePipe implements PipeTransform {
    transform(parameters: Parameter[]): KeyValue<number, string>[] {
        return parameters.map((parameter) => {
            return {
                key: parameter.id,
                value: parameter.nameNl
            };
        });
    }
}

@Pipe({ name: 'parametersToCode' })
export class ParametersToCodePipe implements PipeTransform {
    transform(parameterIds: number[]): { code: number }[] {
        return parameterIds.map((id) => {
            return {
                code: id
            };
        });
    }
}

@Pipe({ name: 'enumToDropdownOptions' })
export class EnumToDropdownOptionsPipe<T> implements PipeTransform {
    transform(data: T[]): DropdownOption<T>[] {
        return Object.values(data).map((value) => {
            return {
                title: value.toString(),
                value: value
            };
        });
    }
}

@Pipe({ name: 'planningFilter' })
export class PlanningFilter implements PipeTransform {
    transform(data: FormattedPlanning[], sampleAuthorityId: number): FormattedPlanning[] {
        return data?.filter((planning) => planning.sampleAuthorityId === sampleAuthorityId);
    }
}

@Pipe({ name: 'dateToMonthPeriod' })
export class DateToMonthPeriod implements PipeTransform {
    transform(value: string): string {
        const today = new Date(value);

        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        return `${firstDay.toString()} - ${lastDay.toString()}`;
    }
}

@Pipe({ name: 'sampleBuyer' })
export class SampleBuyerPipe implements PipeTransform {
    transform(buyerId: number, buyers: DropdownOption<number>[]): string {
        return buyers?.find((buyer) => buyer.value === buyerId)?.title ?? '-';
    }
}

@Pipe({ name: 'sampleProvince' })
export class SampleProvincePipe implements PipeTransform {
    transform(provinceId: number, provinces: DropdownOption<number>[]): string {
        return provinces?.find((province) => province.value === provinceId)?.title ?? '-';
    }
}

@Pipe({ name: 'sampleWarehouse' })
export class SampleWarehousePipe implements PipeTransform {
    transform(warehouseId: number, warehouses: DropdownOption<number>[]): string {
        return warehouses?.find((warehouse) => warehouse.value === warehouseId)?.title ?? '-';
    }
}

@Pipe({ name: 'searchBody' })
export class searchBodyPipe implements PipeTransform {
    constructor(private searchService: SearchService) {}
    transform(SEARCH: any, SORT: any): any {
        return this.searchService.getSearchBody(SEARCH, SORT, null, null);
    }
}

@Pipe({ name: 'objToParams' })
export class objToParamsPipe implements PipeTransform {
    transform(obj: any): any {
        let queryParameters = new HttpParams();
        for (var key in obj) {
            if (Array.isArray(obj[key])) {
                obj[key].forEach((element) => {
                    queryParameters = addToHttpParams(queryParameters, element, `${key}[]`);
                });
            } else {
                queryParameters = addToHttpParams(queryParameters, obj[key], key);
            }
        }
        return queryParameters;
    }
}

function addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
        httpParams = addToHttpParamsRecursive(httpParams, value);
    } else {
        httpParams = addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
}

function addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
        return httpParams;
    }

    if (typeof value === 'object') {
        if (Array.isArray(value)) {
            (value as any[]).forEach((elem) => (httpParams = addToHttpParamsRecursive(httpParams, elem, key)));
        } else if (value instanceof Date) {
            if (key != null) {
                httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
            } else {
                throw Error('key may not be null if value is Date');
            }
        } else {
            Object.keys(value).forEach(
                (k) => (httpParams = addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k))
            );
        }
    } else if (key != null) {
        httpParams = httpParams.append(key, value);
    } else {
        throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
}
