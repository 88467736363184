// Angular
import { Inject, Injectable } from '@angular/core';
// local
import { ConfigService } from './config.service';
import { MonitoringService } from './monitoring.service';
import { APP_CONFIG } from '../../constants';

@Injectable({
    providedIn: 'root'
})
export class InitService {
    constructor(
        private configService: ConfigService,
        private monitoringService: MonitoringService,
        @Inject(APP_CONFIG) private appConfig: any
    ) {}

    initialize(): Promise<any> {
        let that = this;
        let config = { ...this.appConfig };
        return new Promise<void>((resolve, reject) => {
            that.configService.setConfig({
                ...config
            });
            that.monitoringService.init();
            resolve();
        });
    }
}
