<nav [class.collapsed]="collapsed" [class.collapsed-animation-done]="collapsedAnimationDone">
    <button class="button-collapse" (click)="toggleCollapsed()"><i class="im-icon" [class.im-icon-chev-left]="!collapsed" [class.im-icon-chev-right]="collapsed"></i></button>
    <div class="nav-head">
        <a [routerLink]="['/dashboard']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class="logo">
            <img class="hide-collapsed" src="/assets/img/misc/logo-{{user?.language || 'nl'}}.svg" alt="">
            <img class="show-collapsed" src="/assets/img/misc/logo-collapsed.svg" alt="">
        </a>
    </div>
    <div class="nav-body">
        <!-- admin -->
        <ng-container *ngIf="config?.portal === 'admin'">
            <ul class="d-flex flex-column navigation has-sub-navigation" [class.has-sub-navigation--active]="samplesAdminActive">
                <li>
                    <a [routerLink]="['/dashboard']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-dashboard"></i><span>{{'nav_admin_dashboard' | translate}}</span></a>
                </li>
                <li class="is-sub-navigation">
                    <a
                        class="justify-content-between"
                        (click)="!samplesAdminActive ? samplesAdminOpen = !samplesAdminOpen : ''; $event.stopPropagation(); $event.preventDefault()"
                    >
                        <div class="d-flex align-items-center">
                            <i class="im-icon im-icon-pipet"></i>
                            <span>{{'nav_member_samplings' | translate}}</span>
                        </div>
                        <i class="im-icon" [class.im-icon-chev-down]="!samplesAdminOpen" [class.im-icon-chev-up]="samplesAdminOpen"></i>
                    </a>
                    <ul *ngIf="samplesAdminOpen || samplesAdminActive">
                        <li>
                            <a [routerLink]="['/samples/all']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-samples"></i><span>{{'nav_admin_samplings_all' | translate}}</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/samples/registered']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-pencil-square"></i><span>{{'nav_admin_samplings_registered' | translate}}</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/samples/sent']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-box-check-right"></i><span>{{'nav_admin_samplings_sent' | translate}}</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/samples/analysed']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-doc-stats"></i><span>{{'nav_admin_samplings_analysed' | translate}}</span></a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a [routerLink]="['/purchase-orders']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-print-outlined"></i><span>{{ 'nav_admin_purchase-orders' | translate }}</span></a>
                </li>
                <li>
                    <a [routerLink]="['/readings']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-stats"></i><span>{{ 'nav_admin_readings' | translate }}</span></a>
                </li>
            </ul>
            <ul class="d-flex flex-column navigation has-sub-navigation" [class.has-sub-navigation--active]="settingsActive">
                <!-- settings submenu -->
                <li class="is-sub-navigation">
                    <a
                        class="justify-content-between"
                        (click)="!settingsActive ? settingsOpen = !settingsOpen : ''; $event.stopPropagation(); $event.preventDefault()"
                    >
                        <div class="d-flex align-items-center">
                            <i class="im-icon im-icon-settings-outline"></i>
                            <span>{{'nav_admin_settings' | translate}}</span>
                        </div>
                        <i class="im-icon" [class.im-icon-chev-down]="!settingsOpen" [class.im-icon-chev-up]="settingsOpen"></i>
                    </a>
                    <ul *ngIf="settingsOpen || settingsActive">
                        <li>
                            <a [routerLink]="['/settings/planning']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-calendar-settings"></i><span>{{'nav_admin_settings_planning' | translate}}</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/settings/parameters']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-settings-sliders"></i><span>{{'nav_admin_settings_parameters' | translate}}</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/settings/analyses']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-lab"></i><span>{{'nav_admin_settings_analyses' | translate}}</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/settings/buyers']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-cart"></i><span>{{'nav_admin_settings_buyers' | translate}}</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/settings/warehouses']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-store"></i><span>{{'nav_admin_settings_warehouses' | translate}}</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/settings/labos']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-microscope"></i><span>{{'nav_admin_settings_labos' | translate}}</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/settings/samplers']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-pipet"></i><span>{{'nav_admin_settings_samplers' | translate}}</span></a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a [routerLink]="['/users']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-user-settings"></i><span>{{'nav_admin_users' | translate}}</span></a>
                </li>
            </ul>
        </ng-container>
        <!-- member -->
        <ng-container *ngIf="config?.portal === 'member' && user.companyTypeCode === 'sample-authority'">
            <ul class="d-flex flex-column navigation has-sub-navigation" [class.has-sub-navigation--active]="samplesMemberActive">
                <li>
                    <a [routerLink]="['/dashboard']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-dashboard"></i><span>{{'nav_member_dashboard' | translate}}</span></a>
                </li>
                <li class="is-sub-navigation">
                    <a
                        class="justify-content-between"
                        (click)="!samplesMemberActive ? samplesMemberOpen = !samplesMemberOpen : ''; $event.stopPropagation(); $event.preventDefault()"
                    >
                        <div class="d-flex align-items-center">
                            <i class="im-icon im-icon-pipet"></i>
                            <span>{{'nav_member_samplings' | translate}}</span>
                        </div>
                        <i class="im-icon" [class.im-icon-chev-down]="!samplesMemberOpen" [class.im-icon-chev-up]="samplesMemberOpen"></i>
                    </a>
                    <ul *ngIf="samplesMemberOpen || samplesMemberActive">
                        <li>
                            <a [routerLink]="['/samplings/all']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-samples"></i><span>{{'nav_member_samplings_all' | translate}}</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/samplings/registered']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-pencil-square"></i><span>{{'nav_member_samplings_registered' | translate}}</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/samplings/sent']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-box-check-right"></i><span>{{'nav_member_samplings_sent' | translate}}</span></a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a [routerLink]="['/purchase-orders']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-print-outlined"></i><span>{{'nav_member_order_forms' | translate}}</span></a>
                </li>
            </ul>
        </ng-container>
        <!-- buyer -->
        <ng-container *ngIf="config?.portal === 'member' && user.companyTypeCode === 'buyer'">
            <ul class="d-flex flex-column navigation">
                <li>
                    <a [routerLink]="['/my-sample-results']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-lab"></i><span>{{'nav_buyer_my_sample_results' | translate}}</span></a>
                </li>
                <li>
                    <a [routerLink]="['/all-sample-results']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-doc-stats"></i><span>{{'nav_buyer_all_sample_results' | translate}}</span></a>
                </li>
            </ul>
        </ng-container>
    </div>
</nav>

<div [class.collapsed]="collapsed" class="nav-spacer"></div>